.modal-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-wrapper {
  position: relative;
  width: 304px;
  height: 171px;
  padding: 20px;
  background-color: #fcfdff;
  transition: all .8s;
  border-radius: 8px;
  box-sizing: 0px 22px 60px 0px rgba(24, 50, 92, 0.3);
}

.modal-body {
  text-align: center;
}

.close-modal-btn {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -15px;
  right: -15px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000;
  background-color: #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 22px 60px 0px rgba(24, 50, 92, 0.3);
}

@media (min-width: 768px) {
  .modal-wrapper {
    width: 640px;
    height: 360px;
  }
  .close-modal-btn {
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 992px) {
  .modal-wrapper {
    width: 800px;
    height: 450px;
  }
}