.hero--home {
  min-height: 1024px;
  background: initial !important;
  position: relative;
}

.hero__home--text {
  width: 100%;
  margin-top: 0 !important;
}

.home__hero--button {
  flex-direction: column;
  align-items: flex-start;
}

.home__hero--button a + a {
  margin-top: 16px;
}

.hero__home--image {
  max-width: 1204px !important;
  margin: -223px -223px -223px -236px !important;
}

.hero--home-stripes {
  position: absolute;
  z-index: 1;
  transform: skewY(-20deg) translateY(-1150px);
  flex: 1;
  width: 100%;
  height: 1920px;
  background: linear-gradient(228deg, #9F26F1 30%, #0EE8F0 95%) !important;
}

.hero--home-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.hero--home-stripe {
  opacity: 0.5;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  position: absolute;
}

.hero--home-stripe-1 {
  position: absolute;
  bottom: 828px;
  left: -358px;
  width: 920px;
  height: 340px;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  transform-origin: 0 100%;
  opacity: .3;
}

.hero--home-stripe-2 {
  position: absolute;
  bottom: 748px;
  left: 226px;
  width: 158px;
  height: 50px;
  transform-origin: 0 100%;
}

.hero--home-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  opacity: .2;
}

.hero--home-stripe-2:after {
  content: '';
  position: absolute;
  top: 25px;
  left: -12px;
  width: 227px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--home-stripe-3 {
  position: absolute;
  right: 98px;
  bottom: 358px;
  width: 148px;
  height: 54px;
  transform-origin: 0 100%;
}

.hero--home-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  opacity: .3;
}

.hero--home-stripe-3:after {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 227px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--home-stripe-4 {
  position: absolute;
  z-index: 1;
  left: 366px;
  bottom: -25px;
  width: 148px;
  height: 54px;
  transform-origin: 0 100%;
}

.hero--home-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--home-stripe-4:after {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--home-stripe-5 {
  position: absolute;
  z-index: 0;
  bottom: -65px;
  left: 486px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .7;
}

.hero--home-stripe-6 {
  position: absolute;
  z-index: 0;
  right: -425px;
  bottom: -120px;
  width: 920px;
  height: 336px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .3;
}

/* Home Pioneer */
.home__pioneer--stripes-inner {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}
.home__pioneer--stripe-1 {
  position: absolute;
  z-index: 0;
  bottom: -225px;
  left: -232px;
  width: 354px;
  height: 130px;
  transform: skewY(-20deg);
  transform-origin: 0 100%;
  background: linear-gradient(228deg, #CE90FF, #9BE5FF) !important;
  opacity: .3;
}

/* Home Research */
.research__grid--section {
  margin-top: 0 !important;
}
.research__grid {
  grid-template-columns: 100% !important;
}
.home__research--button {
  flex-direction: column;
  align-items: flex-start;
}
.home__research--button a + a {
  margin-top: 16px;
}
.home__research--image {
  position: absolute;
  left: 135px;
  width: 559px;
  height: 553px;
}
.home__research--image:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -58px;
  left: 126px;
  width: 1512px;
  height: 559px;
  transform: skewY(-20deg);
  transform-origin: 0 100%;
  background-image: linear-gradient(180deg, rgba(199, 94, 249, .14), #9630F0);
  opacity: .4;
}
.home__research--image:after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -55px;
  left: -35px;
  width: 1512px;
  height: 288px;
  transform: skewY(-20deg);
  transform-origin: 0 100%;
  background-image: linear-gradient(180deg, rgba(255, 179, 183, .19), #FE7C81);
  opacity: .5;
}
.home__research--image img {
  position: relative;
  z-index: 1;
  width: 671px;
  max-width: 671px;
  height: 616px;
  margin: -18px -32px -45px -80px;
}

/* Home Built */
.built__grid--section {
  margin-top: 128px !important;
}
.built__grid {
  grid-template-columns: 100% !important;
}
.home__built--image {
  position: absolute;
  right: 86px;
  width: 628px;
  height: 389px;
}
.home__built--image img {
  position: relative;
  z-index: 1;
  width: 690px;
  max-width: 690px;
  height: 453px;
  margin: -19px -30px -45px -32px;
}

/* Home Wellbeing */
.wellbeing__grid {
  grid-template-columns: 100% !important;
}
.home__wellbeing--image {
  position: absolute;
  top: 0;
  left: 0;
  width: 804px;
  height: 400px;
}
.home__wellbeing--image img {
  position: relative;
  z-index: 1;
  width: 995px;
  max-width: 995px;
  height: 608px;
  margin: -38px -95px -170px -95px;
}
.wellbeing__text {
  padding-right: 0;
}

/* Home service section */

.gawp__grid--section {
  margin-top: 160px !important;
}
.gawp__grid {
  grid-template-columns: 100% !important;
}

.etsp__grid--section {
  margin-top: 160px !important;
}
.etsp__grid {
  grid-template-columns: 100% !important;
}

.ukd__grid--section {
  margin-top: 160px !important;
}
.ukd__grid {
  grid-template-columns: 100% !important;
}

.ddwsv__grid--section {
  margin-top: 160px !important;
}
.ddwsv__grid {
  grid-template-columns: 100% !important;
}

/* Home service image */

.home__get-a-wellbeing-pulse-image {
  position: absolute;
  top: -122px;
  left: -154px;
  width: 651px;
  height: 536px;
}
.home__get-a-wellbeing-pulse-image img {
  position: relative;
  z-index: 1;
  width: 707px;
  max-width: 707px;
  height: 656px;
  margin: -25px -32px -95px -24px;
}
.home__get-a-wellbeing-pulse-image .home__pattern-big {
  position: absolute;
  z-index: 0;
  bottom: -50px;
  right: 212px;
  width: 1593px;
  height: 552px;
  background-image: linear-gradient(120deg, rgba(199, 94, 249, .14) 50%, #9630F0 150%);
  transform: skewY(20deg);
  transform-origin: 100% 0%;
  opacity: .75;
}
.home__get-a-wellbeing-pulse-image .home__pattern-medium {
  position: absolute;
  z-index: 0;
  bottom: -46px;
  right: -32px;
  width: 1593px;
  height: 204px;
  background-image: linear-gradient(120deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  transform: skewY(20deg);
  transform-origin: 100% 0%;
  opacity: .75;
}
.home__get-a-wellbeing-pulse-image .home__pattern-small {
  position: absolute;
  z-index: 0;
  bottom: 526px;
  right: -52px;
  width: 190px;
  height: 70px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  transform: skewY(20deg);
  transform-origin: 100% 0%;
  opacity: .5;
}

.home__easily-track-student-positivity-image {
  position: absolute;
  top: -107px;
  right: -102px;
  width: 632px;
  height: 555px;
}
.home__easily-track-student-positivity-image img {
  position: relative;
  z-index: 1;
  width: 732px;
  max-width: 732px;
  height: 681px;
  margin: -41px -50px -85px -50px;
}
.home__easily-track-student-positivity-image .home__pattern-big {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -53px;
  width: 731px;
  height: 270px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .75;
}
.home__easily-track-student-positivity-image .home__pattern-medium {
  position: absolute;
  z-index: 0;
  bottom: -50px;
  left: 401px;
  width: 440px;
  height: 160px;
  background-image: linear-gradient(240deg, #6920A4 20%, #EC5FBA 60%, #FFBA78);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .25;
}
.home__easily-track-student-positivity-image .home__pattern-small {
  position: absolute;
  z-index: 0;
  bottom: 420px;
  left: 108px;
  width: 320px;
  height: 120px;
  background-image: linear-gradient(240deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .5;
}

.home__understand-key-drivers-image {
  position: absolute;
  top: -195px;
  left: 0;
  width: 551px;
  height: 682px;
}
.home__understand-key-drivers-image img {
  position: relative;
  z-index: 1;
  width: 651px;
  max-width: 651px;
  height: 808px;
  margin: -41px -50px -85px -50px;
}
.home__understand-key-drivers-image .home__pattern-big {
  position: absolute;
  z-index: 0;
  bottom: 41px;
  right: 81px;
  width: 1593px;
  height: 304px;
  background-image: linear-gradient(120deg, rgba(255, 179, 183, .19), #FE7C81);
  transform: skewY(20deg);
  transform-origin: 100% 0%;
  opacity: .25;
}
.home__understand-key-drivers-image .home__pattern-medium {
  position: absolute;
  z-index: 0;
  bottom: 106px;
  right: -52px;
  width: 769px;
  height: 280px;
  background-image: linear-gradient(120deg, rgba(199, 94, 249, .14) 50%, #9630F0 150%);
  transform: skewY(20deg);
  transform-origin: 100% 0%;
  opacity: .5;
}

.home__dig-deeper-with-student-voice-image {
  position: absolute;
  top: -38px;
  right: -113px;
  width: 578px;
  height: 416px;
}
.home__dig-deeper-with-student-voice-image img {
  position: relative;
  z-index: 1;
  width: 698px;
  max-width: 698px;
  height: 516px;
  margin: -31px -60px -69px -60px;
}
.home__dig-deeper-with-student-voice-image .home__pattern-big {
  position: absolute;
  z-index: 0;
  bottom: -140px;
  left: 18px;
  width: 1278px;
  height: 257px;
  background-image: linear-gradient(240deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .75;
}
.home__dig-deeper-with-student-voice-image .home__pattern-medium {
  position: absolute;
  z-index: 0;
  bottom: -93px;
  left: -90px;
  width: 614px;
  height: 225px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .25;
}
.home__dig-deeper-with-student-voice-image .home__pattern-small {
  position: absolute;
  z-index: 0;
  bottom: 314px;
  left: 82px;
  width: 396px;
  height: 120px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .75;
}
.home__dig-deeper-with-student-voice-image .home__pattern-white-1 {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 482px;
  left: 272px;
  width: 255px;
  height: 90px;
  background-color: #FAFCFD;
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .25;
}
.home__dig-deeper-with-student-voice-image .home__pattern-white-2 {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 316px;
  left: -117px;
  width: 255px;
  height: 96px;
  background-color: #FAFCFD;
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .25;
}
.home__dig-deeper-with-student-voice-image .home__pattern-white-3 {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -141px;
  left: -68px;
  width: 255px;
  height: 96px;
  background-color: #FAFCFD;
  transform: skewY(-20deg);
  transform-origin: 0% 100%;
  opacity: .25;
}

/* */

.hero--cta {
  position: relative;
  z-index: 3 !important;
  margin-top: 246px !important;
  /* padding-bottom: 138px; */
  background: initial !important;
}

.hero--cta-stripes {
  width: 100%;
  height: 610px;
  flex: 1;
  background: #E7F7FF !important;
  transform: skewY(-20deg) translateY(0);
  transform-origin: 50% 0;
  position: absolute;
  z-index: 1;
}

.hero--cta-stripes-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.hero--cta-stripe-1 {
  position: absolute;
  z-index: 0;
  left: -443px;
  top: -282px;
  width: 613px;
  height: 226px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--cta-stripe-2 {
  position: absolute;
  z-index: 0;
  left: 100px;
  top: 100px;
  width: 524px;
  height: 194px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--cta-stripe-3 {
  position: absolute;
  top: 200px;
  left: -230px;
  width: 440px;
  height: 162px;
  transform-origin: 0 100%;
}

.hero--cta-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(225deg,  #6920A4, #EC5FBA 60%,  #FFBA78 100%);
  opacity: .3;
}

.hero--cta-stripe-3:after {
  content: '';
  position: absolute;
  top: 0;
  right: -185px;
  width: 824px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--cta-stripe-4 {
  position: absolute;
  top: 0;
  right: 293px;
  width: 418px;
  height: 154px;
  transform-origin: 0 100%;
}

.hero--cta-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--cta-stripe-4:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: -140px;
  width: 783px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--cta-stripe-5 {
  position: absolute;
  z-index: 0;
  z-index: 0;
  right: -635px;
  top: -100px;
  width: 1004px;
  height: 370px;
  background-image: linear-gradient(60deg,  #9BE5FF,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .5;
}

.hero--cta-stripe-6 {
  position: absolute;
  z-index: 0;
  right: -1308px;
  top: 582px;
  width: 1512px;
  height: 434px;
  background-image: linear-gradient(225deg,  rgba(255, 1789, 183, .19),  #FE7C81);
  transform-origin: 0 100%;
  opacity: .5;
}

.hero--cta-stripe-7 {
  position: absolute;
  z-index: 0;
  bottom: -47px;
  left: 267px;
  width: 255px;
  height: 94px;
  background-color: #fff;
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--why-skodel {
  min-height: 600px;
  margin-top: 240px !important;
  background: initial !important;
  position: relative;
}

.hero--why-skodel-stripes {
  position: absolute;
  width: 100%;
  height: calc(100% + 50px);
  flex: 1;
  background: linear-gradient(120deg,#5E7EF9  0%, #FFA2BA 100%) !important;
  transform: skewY(20deg) translate(0px, -100px);
  z-index: 1;
}

.hero--why-skodel-stripes-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.hero--why-skodel-stripe-1 {
  position: absolute;
  z-index: 1;
  top: -60px;
  left: -320px;
  width: 612px;
  height: 226px;
}

.hero--why-skodel-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  #CE90FF 0%,  #9BE5FF 100%);
  opacity: 0.7;
}

.hero--why-skodel-stripe-1:after {
  content: '';
  position: absolute;
  top: 60px;
  right: -209px;
  width: 256px;
  height: 90px;
  background-color: #FAFCFD;
  opacity: 0.3;
}

.hero--why-skodel-stripe-2 {
  position: absolute;
  z-index: 1;
  top: -286px;
  right: -306px;
  width: 612px;
  height: 226px;
  background-image: linear-gradient(120deg,  #CE90FF 0%,  #9BE5FF 100%);
  opacity: 0.2;
}

.hero--why-skodel-stripe-3 {
  position: absolute;
  z-index: 1;
  bottom: -100px;
  left: -405px;
  width: 1512px;
  height: 288px;
}

.hero--why-skodel-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  rgba(255, 179, 183, .19) 0%,  #FE7C81 100%);
  opacity: 0.3;
}

.hero--why-skodel-stripe-3:after {
  content: '';
  position: absolute;
  top: -50px;
  left: -660px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  rgba(255, 179, 183, .19) 0%,  #FE7C81 100%);
  opacity: 0.3;
}

.hero--why-skodel-stripe-4 {
  position: absolute;
  z-index: 1;
  right: -92px;
  bottom: 0;
  width: 256px;
  height: 94px;
  background-color: #FAFCFD;
  opacity: 0.3;
}

/* Responsive */

@media (min-width: 768px) {
  .hero__home--text {
    margin-top: 92px !important;
  }
  .home__hero--button {
    flex-direction: row;
  }
  .home__hero--button a + a {
    margin-top: 0;
    margin-left: 16px;
  }
  .research__grid--section {
    margin-top: 168px !important;
  }
  .home__research--button {
    flex-direction: row;
  }
  .home__research--button a + a {
    margin-top: 0;
    margin-left: 16px;
  }
  .built__grid--section {
    margin-top: 256px !important;
  }
  .hero--why-skodel {
    margin-top: 480px !important;
  }
  .hero--cta {
    margin-top: 492px !important;
  }
}

@media (min-width: 1000px) {
  .hero__home--text {
    width: 488px;
  }
  .research__grid {
    grid-template-columns: 530px calc(100% - 530px) !important;
  }
  .built__grid {
    grid-template-columns: calc(100% - 523px) 523px !important;
  }
  .wellbeing__grid {
    grid-template-columns: 572px calc(100% - 572px) !important;
  }
  .wellbeing__text {
    padding-right: 107px;
  }
  .gawp__grid--section {
    margin-top: 394px !important;
  }
  .gawp__grid {
    grid-template-columns: calc(100% - 529px) 529px !important;
  }
  .etsp__grid--section {
    margin-top: 538px !important;
  }
  .etsp__grid {
    grid-template-columns: 478px calc(100% - 478px) !important;
  }
  .ukd__grid--section {
    margin-top: 637px !important;
  }
  .ukd__grid {
    grid-template-columns: calc(100% - 431px) 431px !important;
  }
  .ddwsv__grid--section {
    margin-top: 601px !important;
  }
  .ddwsv__grid {
    grid-template-columns: 529px calc(100% - 529px) !important;
  }
}