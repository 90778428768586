
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222A41;
  background: #FAFAFA;
  line-height: 1.65;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100vw;
  /* border-bottom: 1px solid #ccc; */
  /* padding: 32px; */
  /* height: 50px; */
  padding: 40px 32px !important;
}

.main--navbar {
  position: relative;
  z-index: 999;
  width: 100%;
  background-color: transparent;
  transition: all ease .5s;
}

.main--navbar.scrolled {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 12px 30px 0 rgba(55, 74, 105, .05);
  transition: all ease .5s;
}

.main--navbar .navbar {
  transition: all ease .5s;
}

.main--navbar.scrolled .navbar {
  padding: 25px 32px !important;
  transition: all ease .5s;
}

.navbar__wrapper {
  margin-left: 56px;
}

.navbar__items {
  display: -webkit-flex;
  display: flex;
  line-height: 1;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  /* justify-content: space-between; */
}

.navbar__items > a {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}

.navbar__items > a:not(.active) {
  opacity: 0.6;
}

.navbar-outer--active .navbar__items > a:not(.active) {
  color: rgb(130, 129, 138);
  opacity: 1;
}

.navbar-outer--active .navbar__items > a.active {
  color: #5DAAF2;
}

.navbar-outer--active .navbar__user > a {
  color: rgb(130, 129, 138);
}

.navbar-outer--active .navbar__user > a.btn__user--join {
  color: #5DAAF2;
  border: 1px solid #5DAAF2;
}

.navbar__items > a + a {
  margin-left: 30px;
}

.navbar__items > li:last-of-type {
  margin-right: 0;
}

.navbar__user > a {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  color: #ffffff;
}

.navbar__user > a.btn__user--join {
  padding: 10px 25px;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 3px;
}

.navbar__user > a + a {
  margin-left: 30px;
}

.hero {
  /* navbar height is 90px */
  padding-top: 120px;
}

.navbar__logo {
  width: 90px;
  /* margin-right: 32px; */
}


/* .cards > div:nth-child(odd) {
  background: #FAFAFA;
} */

ul > li,ol > li {
  margin-bottom: 16px;
}

ul, ol {
  padding-top: 16px;
}

.hamburger-icon {
  display: none !important;
}

.hamburger-icon-wrap {
  /* display: none; */
}

@media (max-width: 1440px) {
  .brand-stripes {
   overflow-x: hidden;
   overflow-y: hidden;
   /* overflow-y: scroll; */
  }
}

@media (max-width: 1000px) {
  .navbar__items,
  .navbar__user {
  display: none;
  -webkit-flex: unset;
          flex: unset;
  }

  .hamburger-icon-wrap {
    -webkit-flex: 1 1;
            flex: 1 1;
    /* display: none; */
  }

  .hamburger-icon {
    display: inline-block !important;
  }
}

.burger-menu-open  {
  box-shadow:   0 0 48px 0 rgba(0,0,0,0.9);
}
.bm-menu {
  background: #373a47;
}

.bm-item-list {
  margin-top: 48px;
}

.bm-item {
  padding: 16px 48px;
  color: white;
  text-decoration: none;
}

.bm-item.active {
  font-weight: bold;
}
.modal-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.modal-wrapper {
  position: relative;
  width: 304px;
  height: 171px;
  padding: 20px;
  background-color: #fcfdff;
  transition: all .8s;
  border-radius: 8px;
  box-sizing: 0px 22px 60px 0px rgba(24, 50, 92, 0.3);
}

.modal-body {
  text-align: center;
}

.close-modal-btn {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -15px;
  right: -15px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000;
  background-color: #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 22px 60px 0px rgba(24, 50, 92, 0.3);
}

@media (min-width: 768px) {
  .modal-wrapper {
    width: 640px;
    height: 360px;
  }
  .close-modal-btn {
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 992px) {
  .modal-wrapper {
    width: 800px;
    height: 450px;
  }
}
.Global_hero__title__3SMnR {
  margin: 0 !important;
  font-size: 42px !important;
  font-weight: 700 !important;
  line-height: 57px;
}

.Global_hero__subtitle__2ZAXQ {
  margin: 16px 0 0 !important;
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 30px;
}

/* Custom tabs */
.Global_custom--tabs__2bhsR {
  background-color: #ffffff;
  box-shadow: 0 22px 60px 0 rgba(24, 50, 92, .1);
}

.Global_tab__header__3jmdo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #d8d8d8;
}

.Global_tab__nav--list__3LdOE {
  cursor: pointer;
  margin-bottom: -1px;
  padding: 20px 0;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #7b7b7b;
  border-bottom: 1px solid #d8d8d8;
}

.Global_tab__nav--list__3LdOE:focus {
  box-shadow: none;
  outline: none;
}

.Global_tab--active__3zSUF {
  font-weight: 700;
  color: #aa63ff;
  border-bottom: 1px solid #aa63ff;
}

/* Button */
.Global_btn__1_Kk9 {
  position: relative;
  top: 0;
  transition: all ease .3s;
}

.Global_btn__1_Kk9:hover {
  top: -3px;
  transition: all ease .3s;
}

/* Footer */
.Global_hero--footer__1YVHe {
  position: relative;
  z-index: 1;
  padding: 550px 0 80px;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.Global_hero--footer-stripes__xm5D0 {
  -webkit-flex: 1 1;
          flex: 1 1;
  position: absolute;
  top: 305px;
  z-index: 1;
  -webkit-transform: skewY(-20deg) translateY(0);
          transform: skewY(-20deg) translateY(0);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  width: 100%;
  height: 1584px;
  background: linear-gradient(160deg, #5E7EF9 20%, #FFA2BA 70%) !important;
}

.Global_hero--footer-stripes-inner__1lBeV {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.Global_hero--footer-stripe-1__C8Giq {
  position: absolute;
  z-index: 0;
  top: -47px;
  left: 267px;
  width: 255px;
  height: 94px;
  background-color: #fff;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Global_hero--footer-stripe-2__-ZrVX {
  position: absolute;
  z-index: 0;
  left: 931px;
  top: 610px;
  width: 675px;
  height: 250px;
  background-color: #fff;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Global_hero--footer-stripe-3__3w2p4 {
  position: absolute;
  top: 130px;
  left: 1133px;
  width: 614px;
  height: 227px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Global_hero--footer-stripe-3__3w2p4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9be5ff, #cd91ff);
  opacity: .7;
}

.Global_hero--footer-stripe-3__3w2p4:after {
  content: '';
  position: absolute;
  top: 227px;
  left: 209px;
  width: 255px;
  height: 94px;
  background-color: #FEFEFE;
  opacity: .3;
}

.Global_hero--footer-wrapper__1MUA2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Global_hero--footer-left___f4FG {
  margin: 100px 0 0 !important;
  text-align: center;
}

.Global_hero--footer-right__2iLZv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Global_hero--footer-menu__2Fy9q {
  text-align: center;
}

.Global_hero--footer-menu__2Fy9q + .Global_hero--footer-menu__2Fy9q {
  margin: 50px 0 0 !important;
}

/* Responsive */

@media (min-width: 768px) {
  .Global_hero--footer-right__2iLZv {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .Global_hero--footer-menu__2Fy9q {
    text-align: left;
  }

  .Global_hero--footer-menu__2Fy9q + .Global_hero--footer-menu__2Fy9q {
    margin: 0 0 0 80px !important;
  }
}

@media (min-width: 1200px) {
  .Global_hero--footer-wrapper__1MUA2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .Global_hero--footer-left___f4FG {
    margin: 0 !important;
    text-align: left;
  }
}
.Home_hero--home__2iv_W {
  min-height: 1024px;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  position: relative;
}

.Home_hero__home--text__2JZim {
  width: 100%;
  margin-top: 0 !important;
}

.Home_home__hero--button__1tGuA {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.Home_home__hero--button__1tGuA a + a {
  margin-top: 16px;
}

.Home_hero__home--image__n8Xcq {
  max-width: 1204px !important;
  margin: -223px -223px -223px -236px !important;
}

.Home_hero--home-stripes__mFP2i {
  position: absolute;
  z-index: 1;
  -webkit-transform: skewY(-20deg) translateY(-1150px);
          transform: skewY(-20deg) translateY(-1150px);
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 1920px;
  background: linear-gradient(228deg, #9F26F1 30%, #0EE8F0 95%) !important;
}

.Home_hero--home-stripes-inner__3v-qO {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.Home_hero--home-stripe__3-gtw {
  opacity: 0.5;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  position: absolute;
}

.Home_hero--home-stripe-1__2c2xE {
  position: absolute;
  bottom: 828px;
  left: -358px;
  width: 920px;
  height: 340px;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .3;
}

.Home_hero--home-stripe-2__3m6QH {
  position: absolute;
  bottom: 748px;
  left: 226px;
  width: 158px;
  height: 50px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Home_hero--home-stripe-2__3m6QH:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  opacity: .2;
}

.Home_hero--home-stripe-2__3m6QH:after {
  content: '';
  position: absolute;
  top: 25px;
  left: -12px;
  width: 227px;
  height: 1px;
  background-color: #FEFEFE;
}

.Home_hero--home-stripe-3__2lq-m {
  position: absolute;
  right: 98px;
  bottom: 358px;
  width: 148px;
  height: 54px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Home_hero--home-stripe-3__2lq-m:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(100deg,  #9BE5FF 50%,  #CE90FF 100%);
  opacity: .3;
}

.Home_hero--home-stripe-3__2lq-m:after {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 227px;
  height: 1px;
  background-color: #FEFEFE;
}

.Home_hero--home-stripe-4__1LG3L {
  position: absolute;
  z-index: 1;
  left: 366px;
  bottom: -25px;
  width: 148px;
  height: 54px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Home_hero--home-stripe-4__1LG3L:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.Home_hero--home-stripe-4__1LG3L:after {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.Home_hero--home-stripe-5__Od4Po {
  position: absolute;
  z-index: 0;
  bottom: -65px;
  left: 486px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .7;
}

.Home_hero--home-stripe-6__oLJ-R {
  position: absolute;
  z-index: 0;
  right: -425px;
  bottom: -120px;
  width: 920px;
  height: 336px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .3;
}

/* Home Pioneer */
.Home_home__pioneer--stripes-inner__31kln {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}
.Home_home__pioneer--stripe-1__1pz9l {
  position: absolute;
  z-index: 0;
  bottom: -225px;
  left: -232px;
  width: 354px;
  height: 130px;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  background: linear-gradient(228deg, #CE90FF, #9BE5FF) !important;
  opacity: .3;
}

/* Home Research */
.Home_research__grid--section__3CK-3 {
  margin-top: 0 !important;
}
.Home_research__grid__1vB69 {
  grid-template-columns: 100% !important;
}
.Home_home__research--button__S0old {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.Home_home__research--button__S0old a + a {
  margin-top: 16px;
}
.Home_home__research--image__21Sth {
  position: absolute;
  left: 135px;
  width: 559px;
  height: 553px;
}
.Home_home__research--image__21Sth:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -58px;
  left: 126px;
  width: 1512px;
  height: 559px;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  background-image: linear-gradient(180deg, rgba(199, 94, 249, .14), #9630F0);
  opacity: .4;
}
.Home_home__research--image__21Sth:after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -55px;
  left: -35px;
  width: 1512px;
  height: 288px;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  background-image: linear-gradient(180deg, rgba(255, 179, 183, .19), #FE7C81);
  opacity: .5;
}
.Home_home__research--image__21Sth img {
  position: relative;
  z-index: 1;
  width: 671px;
  max-width: 671px;
  height: 616px;
  margin: -18px -32px -45px -80px;
}

/* Home Built */
.Home_built__grid--section__265gT {
  margin-top: 128px !important;
}
.Home_built__grid__1mffu {
  grid-template-columns: 100% !important;
}
.Home_home__built--image__wJCqR {
  position: absolute;
  right: 86px;
  width: 628px;
  height: 389px;
}
.Home_home__built--image__wJCqR img {
  position: relative;
  z-index: 1;
  width: 690px;
  max-width: 690px;
  height: 453px;
  margin: -19px -30px -45px -32px;
}

/* Home Wellbeing */
.Home_wellbeing__grid__2PI_T {
  grid-template-columns: 100% !important;
}
.Home_home__wellbeing--image__MJnru {
  position: absolute;
  top: 0;
  left: 0;
  width: 804px;
  height: 400px;
}
.Home_home__wellbeing--image__MJnru img {
  position: relative;
  z-index: 1;
  width: 995px;
  max-width: 995px;
  height: 608px;
  margin: -38px -95px -170px -95px;
}
.Home_wellbeing__text__12eqM {
  padding-right: 0;
}

/* Home service section */

.Home_gawp__grid--section__20bmz {
  margin-top: 160px !important;
}
.Home_gawp__grid___2q72 {
  grid-template-columns: 100% !important;
}

.Home_etsp__grid--section__1hMkN {
  margin-top: 160px !important;
}
.Home_etsp__grid__3DHCG {
  grid-template-columns: 100% !important;
}

.Home_ukd__grid--section__3p5lh {
  margin-top: 160px !important;
}
.Home_ukd__grid__2cqqc {
  grid-template-columns: 100% !important;
}

.Home_ddwsv__grid--section__3Ftne {
  margin-top: 160px !important;
}
.Home_ddwsv__grid__3OYAU {
  grid-template-columns: 100% !important;
}

/* Home service image */

.Home_home__get-a-wellbeing-pulse-image__Vurwl {
  position: absolute;
  top: -122px;
  left: -154px;
  width: 651px;
  height: 536px;
}
.Home_home__get-a-wellbeing-pulse-image__Vurwl img {
  position: relative;
  z-index: 1;
  width: 707px;
  max-width: 707px;
  height: 656px;
  margin: -25px -32px -95px -24px;
}
.Home_home__get-a-wellbeing-pulse-image__Vurwl .Home_home__pattern-big__2eelP {
  position: absolute;
  z-index: 0;
  bottom: -50px;
  right: 212px;
  width: 1593px;
  height: 552px;
  background-image: linear-gradient(120deg, rgba(199, 94, 249, .14) 50%, #9630F0 150%);
  -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  opacity: .75;
}
.Home_home__get-a-wellbeing-pulse-image__Vurwl .Home_home__pattern-medium__1jVC0 {
  position: absolute;
  z-index: 0;
  bottom: -46px;
  right: -32px;
  width: 1593px;
  height: 204px;
  background-image: linear-gradient(120deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  opacity: .75;
}
.Home_home__get-a-wellbeing-pulse-image__Vurwl .Home_home__pattern-small__2kYhR {
  position: absolute;
  z-index: 0;
  bottom: 526px;
  right: -52px;
  width: 190px;
  height: 70px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  opacity: .5;
}

.Home_home__easily-track-student-positivity-image__2UMmH {
  position: absolute;
  top: -107px;
  right: -102px;
  width: 632px;
  height: 555px;
}
.Home_home__easily-track-student-positivity-image__2UMmH img {
  position: relative;
  z-index: 1;
  width: 732px;
  max-width: 732px;
  height: 681px;
  margin: -41px -50px -85px -50px;
}
.Home_home__easily-track-student-positivity-image__2UMmH .Home_home__pattern-big__2eelP {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -53px;
  width: 731px;
  height: 270px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .75;
}
.Home_home__easily-track-student-positivity-image__2UMmH .Home_home__pattern-medium__1jVC0 {
  position: absolute;
  z-index: 0;
  bottom: -50px;
  left: 401px;
  width: 440px;
  height: 160px;
  background-image: linear-gradient(240deg, #6920A4 20%, #EC5FBA 60%, #FFBA78);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .25;
}
.Home_home__easily-track-student-positivity-image__2UMmH .Home_home__pattern-small__2kYhR {
  position: absolute;
  z-index: 0;
  bottom: 420px;
  left: 108px;
  width: 320px;
  height: 120px;
  background-image: linear-gradient(240deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .5;
}

.Home_home__understand-key-drivers-image__1_IGN {
  position: absolute;
  top: -195px;
  left: 0;
  width: 551px;
  height: 682px;
}
.Home_home__understand-key-drivers-image__1_IGN img {
  position: relative;
  z-index: 1;
  width: 651px;
  max-width: 651px;
  height: 808px;
  margin: -41px -50px -85px -50px;
}
.Home_home__understand-key-drivers-image__1_IGN .Home_home__pattern-big__2eelP {
  position: absolute;
  z-index: 0;
  bottom: 41px;
  right: 81px;
  width: 1593px;
  height: 304px;
  background-image: linear-gradient(120deg, rgba(255, 179, 183, .19), #FE7C81);
  -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  opacity: .25;
}
.Home_home__understand-key-drivers-image__1_IGN .Home_home__pattern-medium__1jVC0 {
  position: absolute;
  z-index: 0;
  bottom: 106px;
  right: -52px;
  width: 769px;
  height: 280px;
  background-image: linear-gradient(120deg, rgba(199, 94, 249, .14) 50%, #9630F0 150%);
  -webkit-transform: skewY(20deg);
          transform: skewY(20deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  opacity: .5;
}

.Home_home__dig-deeper-with-student-voice-image__3t1A3 {
  position: absolute;
  top: -38px;
  right: -113px;
  width: 578px;
  height: 416px;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 img {
  position: relative;
  z-index: 1;
  width: 698px;
  max-width: 698px;
  height: 516px;
  margin: -31px -60px -69px -60px;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-big__2eelP {
  position: absolute;
  z-index: 0;
  bottom: -140px;
  left: 18px;
  width: 1278px;
  height: 257px;
  background-image: linear-gradient(240deg, rgba(255, 179, 183, .19) 50%, #FE7C81 150%);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .75;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-medium__1jVC0 {
  position: absolute;
  z-index: 0;
  bottom: -93px;
  left: -90px;
  width: 614px;
  height: 225px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .25;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-small__2kYhR {
  position: absolute;
  z-index: 0;
  bottom: 314px;
  left: 82px;
  width: 396px;
  height: 120px;
  background-image: linear-gradient(240deg, #CE90FF, #9BE5FF);
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .75;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-white-1__um6IN {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 482px;
  left: 272px;
  width: 255px;
  height: 90px;
  background-color: #FAFCFD;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .25;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-white-2__34d5I {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 316px;
  left: -117px;
  width: 255px;
  height: 96px;
  background-color: #FAFCFD;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .25;
}
.Home_home__dig-deeper-with-student-voice-image__3t1A3 .Home_home__pattern-white-3__3nSWq {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -141px;
  left: -68px;
  width: 255px;
  height: 96px;
  background-color: #FAFCFD;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  opacity: .25;
}

/* */

.Home_hero--cta__3ggU8 {
  position: relative;
  z-index: 3 !important;
  margin-top: 246px !important;
  /* padding-bottom: 138px; */
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.Home_hero--cta-stripes__2-wKV {
  width: 100%;
  height: 610px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #E7F7FF !important;
  -webkit-transform: skewY(-20deg) translateY(0);
          transform: skewY(-20deg) translateY(0);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  position: absolute;
  z-index: 1;
}

.Home_hero--cta-stripes-inner__1aobm {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.Home_hero--cta-stripe-1__1eYtp {
  position: absolute;
  z-index: 0;
  left: -443px;
  top: -282px;
  width: 613px;
  height: 226px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Home_hero--cta-stripe-2__3q6Rr {
  position: absolute;
  z-index: 0;
  left: 100px;
  top: 100px;
  width: 524px;
  height: 194px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Home_hero--cta-stripe-3__3AYVb {
  position: absolute;
  top: 200px;
  left: -230px;
  width: 440px;
  height: 162px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Home_hero--cta-stripe-3__3AYVb:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(225deg,  #6920A4, #EC5FBA 60%,  #FFBA78 100%);
  opacity: .3;
}

.Home_hero--cta-stripe-3__3AYVb:after {
  content: '';
  position: absolute;
  top: 0;
  right: -185px;
  width: 824px;
  height: 1px;
  background-color: #FEFEFE;
}

.Home_hero--cta-stripe-4__3RQGY {
  position: absolute;
  top: 0;
  right: 293px;
  width: 418px;
  height: 154px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Home_hero--cta-stripe-4__3RQGY:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.Home_hero--cta-stripe-4__3RQGY:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: -140px;
  width: 783px;
  height: 1px;
  background-color: #FEFEFE;
}

.Home_hero--cta-stripe-5__3YFgm {
  position: absolute;
  z-index: 0;
  z-index: 0;
  right: -635px;
  top: -100px;
  width: 1004px;
  height: 370px;
  background-image: linear-gradient(60deg,  #9BE5FF,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .5;
}

.Home_hero--cta-stripe-6__2D485 {
  position: absolute;
  z-index: 0;
  right: -1308px;
  top: 582px;
  width: 1512px;
  height: 434px;
  background-image: linear-gradient(225deg,  rgba(255, 1789, 183, .19),  #FE7C81);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .5;
}

.Home_hero--cta-stripe-7__1ONKZ {
  position: absolute;
  z-index: 0;
  bottom: -47px;
  left: 267px;
  width: 255px;
  height: 94px;
  background-color: #fff;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Home_hero--why-skodel__1Ps2s {
  min-height: 600px;
  margin-top: 240px !important;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  position: relative;
}

.Home_hero--why-skodel-stripes__3hPJP {
  position: absolute;
  width: 100%;
  height: calc(100% + 50px);
  -webkit-flex: 1 1;
          flex: 1 1;
  background: linear-gradient(120deg,#5E7EF9  0%, #FFA2BA 100%) !important;
  -webkit-transform: skewY(20deg) translate(0px, -100px);
          transform: skewY(20deg) translate(0px, -100px);
  z-index: 1;
}

.Home_hero--why-skodel-stripes-inner__kfg5l {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.Home_hero--why-skodel-stripe-1__2aSes {
  position: absolute;
  z-index: 1;
  top: -60px;
  left: -320px;
  width: 612px;
  height: 226px;
}

.Home_hero--why-skodel-stripe-1__2aSes:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  #CE90FF 0%,  #9BE5FF 100%);
  opacity: 0.7;
}

.Home_hero--why-skodel-stripe-1__2aSes:after {
  content: '';
  position: absolute;
  top: 60px;
  right: -209px;
  width: 256px;
  height: 90px;
  background-color: #FAFCFD;
  opacity: 0.3;
}

.Home_hero--why-skodel-stripe-2__G942- {
  position: absolute;
  z-index: 1;
  top: -286px;
  right: -306px;
  width: 612px;
  height: 226px;
  background-image: linear-gradient(120deg,  #CE90FF 0%,  #9BE5FF 100%);
  opacity: 0.2;
}

.Home_hero--why-skodel-stripe-3__2hoGR {
  position: absolute;
  z-index: 1;
  bottom: -100px;
  left: -405px;
  width: 1512px;
  height: 288px;
}

.Home_hero--why-skodel-stripe-3__2hoGR:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  rgba(255, 179, 183, .19) 0%,  #FE7C81 100%);
  opacity: 0.3;
}

.Home_hero--why-skodel-stripe-3__2hoGR:after {
  content: '';
  position: absolute;
  top: -50px;
  left: -660px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,  rgba(255, 179, 183, .19) 0%,  #FE7C81 100%);
  opacity: 0.3;
}

.Home_hero--why-skodel-stripe-4__1GqE3 {
  position: absolute;
  z-index: 1;
  right: -92px;
  bottom: 0;
  width: 256px;
  height: 94px;
  background-color: #FAFCFD;
  opacity: 0.3;
}

/* Responsive */

@media (min-width: 768px) {
  .Home_hero__home--text__2JZim {
    margin-top: 92px !important;
  }
  .Home_home__hero--button__1tGuA {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .Home_home__hero--button__1tGuA a + a {
    margin-top: 0;
    margin-left: 16px;
  }
  .Home_research__grid--section__3CK-3 {
    margin-top: 168px !important;
  }
  .Home_home__research--button__S0old {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .Home_home__research--button__S0old a + a {
    margin-top: 0;
    margin-left: 16px;
  }
  .Home_built__grid--section__265gT {
    margin-top: 256px !important;
  }
  .Home_hero--why-skodel__1Ps2s {
    margin-top: 480px !important;
  }
  .Home_hero--cta__3ggU8 {
    margin-top: 492px !important;
  }
}

@media (min-width: 1000px) {
  .Home_hero__home--text__2JZim {
    width: 488px;
  }
  .Home_research__grid__1vB69 {
    grid-template-columns: 530px calc(100% - 530px) !important;
  }
  .Home_built__grid__1mffu {
    grid-template-columns: calc(100% - 523px) 523px !important;
  }
  .Home_wellbeing__grid__2PI_T {
    grid-template-columns: 572px calc(100% - 572px) !important;
  }
  .Home_wellbeing__text__12eqM {
    padding-right: 107px;
  }
  .Home_gawp__grid--section__20bmz {
    margin-top: 394px !important;
  }
  .Home_gawp__grid___2q72 {
    grid-template-columns: calc(100% - 529px) 529px !important;
  }
  .Home_etsp__grid--section__1hMkN {
    margin-top: 538px !important;
  }
  .Home_etsp__grid__3DHCG {
    grid-template-columns: 478px calc(100% - 478px) !important;
  }
  .Home_ukd__grid--section__3p5lh {
    margin-top: 637px !important;
  }
  .Home_ukd__grid__2cqqc {
    grid-template-columns: calc(100% - 431px) 431px !important;
  }
  .Home_ddwsv__grid--section__3Ftne {
    margin-top: 601px !important;
  }
  .Home_ddwsv__grid__3OYAU {
    grid-template-columns: 529px calc(100% - 529px) !important;
  }
}
.AboutUs_hero--about-us__3RnDS {
  position: relative;
  z-index: 3 !important;
  min-height: 1024px;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.AboutUs_hero--about-us__grid__qJY8u {
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  grid-row-gap: 32px;
  row-gap: 32px;
}

.AboutUs_hero--about-us-stripes__rIOTo {
  position: absolute;
  z-index: 0;
  -webkit-transform: skewY(-20deg) translateY(-1152px);
          transform: skewY(-20deg) translateY(-1152px);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 1920px;
  background: #f2eeff !important;
}

.AboutUs_hero--about-us-stripes-inner__1GQyn {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.AboutUs_hero--about-us-stripe-1__2HB53 {
  position: absolute;
  bottom: 664px;
  left: -112px;
  width: 284px;
  height: 104px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.AboutUs_hero--about-us-stripe-2__3Yxcb {
  position: absolute;
  bottom: 630px;
  left: 98px;
  width: 148px;
  height: 54px;
}

.AboutUs_hero--about-us-stripe-2__3Yxcb:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.AboutUs_hero--about-us-stripe-2__3Yxcb:after {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.AboutUs_hero--about-us-stripe-3__350UH {
  position: absolute;
  bottom: 536px;
  left: 80px;
  width: 354px;
  height: 130px;
}

.AboutUs_hero--about-us-stripe-3__350UH:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.AboutUs_hero--about-us-stripe-3__350UH:after {
  content: '';
  position: absolute;
  top: 82px;
  right: -80px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.AboutUs_hero--about-us-stripe-4__YEEvT {
  position: absolute;
  bottom: 470px;
  left: 146px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.AboutUs_hero--about-us-stripe-5__oQYsp {
  position: absolute;
  bottom: -30px;
  left: -50px;
  width: 142px;
  height: 52px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.AboutUs_hero--about-us-stripe-6__3E0Vd {
  position: absolute;
  right: -46px;
  bottom: 127px;
  width: 284px;
  height: 104px;
}

.AboutUs_hero--about-us-stripe-6__3E0Vd:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.AboutUs_hero--about-us-stripe-6__3E0Vd:after {
  content: '';
  position: absolute;
  top: 35px;
  left: -48px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.AboutUs_hero--about-us-stripe-7__3DpX- {
  position: absolute;
  right: -16px;
  bottom: 0;
  width: 354px;
  height: 128px;
}

.AboutUs_hero--about-us-stripe-7__3DpX-:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.AboutUs_hero--about-us-stripe-7__3DpX-:after {
  content: '';
  position: absolute;
  top: -26px;
  left: 70px;
  width: 148px;
  height: 54px;
  background-color: #FEFEFE;
  opacity: .3;
}

/* Team */
.AboutUs_team--about-us__36duG {
  position: relative;
  z-index: 2 !important;
  margin-top: 120px !important;
}

.AboutUs_team--about-us-stripes__3CN5A {
  position: absolute;
  z-index: 0;
  -webkit-transform: skewY(-20deg) translateY(-1152px);
          transform: skewY(-20deg) translateY(-1152px);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 1920px;
}

.AboutUs_team--about-us-stripes-inner__1KcRZ {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.AboutUs_team--about-us-stripe-1__1uCi_ {
  position: absolute;
  bottom: 444px;
  right: -15px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.AboutUs_team--about-us-stripe-2__ZaZVw {
  position: absolute;
  bottom: -244px;
  right: -15px;
  width: 1004px;
  height: 366px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .2;
}

.AboutUs_team--about-us-stripe-3__29fPu {
  position: absolute;
  bottom: 400px;
  left: -98px;
  -webkit-transform: skewY(40deg);
          transform: skewY(40deg);
  width: 198px;
  height: 72px;
}

.AboutUs_team--about-us-stripe-3__29fPu:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_team--about-us-stripe-3__29fPu:after {
  content: '';
  position: absolute;
  top: 40px;
  right: -76px;
  width: 278px;
  height: 1px;
  background-color: #D1C5FF;
}

.AboutUs_team--about-us-stripe-4__n1M4I {
  position: absolute;
  bottom: 400px;
  left: -188px;
  -webkit-transform: skewY(40deg);
          transform: skewY(40deg);
  width: 248px;
  height: 92px;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_team--about-us__content__1WD3r {
  position: relative;
  z-index: 1;
}

.AboutUs_team--about-us__grid__1NIXp {
  grid-template-columns: 1fr;
}

/* Building */
.AboutUs_building--about__2PxUJ {
  position: relative;
  padding: 92px 0 128px;
}

.AboutUs_building--about-grid__-iMww {
  grid-template-columns: 1fr;
}

.AboutUs_building--about-stripes__12dJy {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: skewY(20deg) translate(-50%, -800px);
          transform: skewY(20deg) translate(-50%, -800px);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100vw;
  height: 1920px;
  background: linear-gradient(120deg, #5E7EF9, #FFA2BA) !important;
}

.AboutUs_building--about-stripes-inner__YOjb4 {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.AboutUs_building--about-stripe-1__93D_J {
  position: absolute;
  top: 242px;
  left: 262px;
  width: 198px;
  height: 72px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_building--about-stripe-2__2eKK6 {
  position: absolute;
  top: 284px;
  left: 172px;
  width: 248px;
  height: 92px;
}

.AboutUs_building--about-stripe-2__2eKK6:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_building--about-stripe-2__2eKK6:after {
  content: '';
  position: absolute;
  top: 0;
  left: -82px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.AboutUs_building--about-stripe-3__2nt7n {
  position: absolute;
  top: 100px;
  right: -172px;
  width: 520px;
  height: 126px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_building--about-stripe-4__1r-6P {
  position: absolute;
  top: 65px;
  right: 72px;
  width: 218px;
  height: 80px;
}

.AboutUs_building--about-stripe-4__1r-6P:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9630F0;
  opacity: .1;
}

.AboutUs_building--about-stripe-4__1r-6P:after {
  content: '';
  position: absolute;
  top: 0;
  left: -92px;
  width: 408px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.AboutUs_building--about-stripe-5__2jUmd {
  position: absolute;
  top: 528px;
  right: -80px;
  width: 284px;
  height: 104px;
}

.AboutUs_building--about-stripe-5__2jUmd:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_building--about-stripe-5__2jUmd:after {
  content: '';
  position: absolute;
  top: 50px;
  left: -129px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.AboutUs_building--about-content__2zivz {
  position: relative;
  z-index: 1;
}

.AboutUs_my-masonry-grid__2anL6 {
  display: -webkit-flex;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.AboutUs_my-masonry-grid_column__23kXs {
  padding-left: 30px;
  background-clip: padding-box;
}

/* Style your items */
.AboutUs_my-masonry-grid_column__23kXs > div {
  background: grey;
  margin-bottom: 30px;
}

/* Investor */
.AboutUs_investor--about__grid__2wwol {
  grid-template-columns: 1fr 1fr;
}

/* Join team */
.AboutUs_join-team--about__z90nk {
  position: relative;
  padding: 100px 0 100px;
}

.AboutUs_join-team--about-stripes__fuF8P {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  -webkit-transform: skewY(20deg) translate(-50%, 680px);
          transform: skewY(20deg) translate(-50%, 680px);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100vw;
  height: 1920px;
  background: linear-gradient(120deg, #E7F7FF, #DDE9F7) !important;
}

.AboutUs_join-team--about-stripes-inner__19pce {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.AboutUs_join-team--about-stripe-1__3NPX0 {
  position: absolute;
  top: 448px;
  left: -745px;
  width: 1004px;
  height: 366px;
}

.AboutUs_join-team--about-stripe-1__3NPX0:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.AboutUs_join-team--about-stripe-1__3NPX0:after {
  content: '';
  position: absolute;
  top: -86px;
  right: -165px;
  width: 418px;
  height: 154px;
  background-color: #FAFCFD;
  opacity: .7;
}

.AboutUs_join-team--about-stripe-2__MoE_a {
  position: absolute;
  top: 58px;
  right: 170px;
  width: 354px;
  height: 130px;
}

.AboutUs_join-team--about-stripe-2__MoE_a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.AboutUs_join-team--about-stripe-2__MoE_a:after {
  content: '';
  position: absolute;
  top: 96px;
  left: -82px;
  width: 276px;
  height: 1px;
  background-color: #FAFCFD;
  opacity: .7;
}

.AboutUs_join-team--about-stripe-3__1tvRf {
  position: absolute;
  top: 950px;
  left: 526px;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.AboutUs_join-team--about-stripe-4__2PLOU {
  position: absolute;
  top: 540px;
  right: 105px;
  width: 283px;
  height: 104px;
}

.AboutUs_join-team--about-stripe-4__2PLOU:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9DBFF5, #9BE5FF);
  opacity: .7;
}

.AboutUs_join-team--about-stripe-4__2PLOU:after {
  content: '';
  position: absolute;
  top: 70px;
  right: -26px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.AboutUs_join-team--about-stripe-5__167jV {
  position: absolute;
  top: -55px;
  right: 0;
  width: 898px;
  height: 172px;
  background-image: linear-gradient(120deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.AboutUs_join-team--about-stripe-6__3cUFc {
  position: absolute;
  top: -126px;
  right: -230px;
  width: 1272px;
  height: 126px;
  background-image: linear-gradient(120deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.AboutUs_join-team--about-stripe-7__2LmU9 {
  position: absolute;
  top: -94px;
  left: -188px;
  width: 354px;
  height: 129px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.AboutUs_join-team--about-stripe-8__33POU {
  position: absolute;
  top: -54px;
  left: 138px;
  width: 148px;
  height: 54px;
}

.AboutUs_join-team--about-stripe-8__33POU:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.AboutUs_join-team--about-stripe-8__33POU:after {
  content: '';
  position: absolute;
  top: 0;
  left: -62px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.AboutUs_join-team--about__content__qLEcs {
  position: relative;
  z-index: 1;
}

.AboutUs_join-team--about__grid__1pgR1 {
  grid-template-columns: 1fr;
}

.AboutUs_join-team--about__list__3L3x6 {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px !important;
}

.AboutUs_join-team--about__list__3L3x6 h2 {
  margin-bottom: 20px !important;
}

/* Responsive */

@media (min-width: 576px) {
  .AboutUs_investor--about__grid__2wwol {
    max-width: 360px;
  }

  .AboutUs_join-team--about__grid__1pgR1 {
    grid-template-columns: 1fr 1fr;
  }

  .AboutUs_join-team--about-stripes__fuF8P {
    -webkit-transform: skewY(20deg) translate(-50%, 1100px);
            transform: skewY(20deg) translate(-50%, 1100px);
  }
}

@media (min-width: 768px) {
  .AboutUs_hero--about-us__grid__qJY8u {
    grid-template-columns: 1fr 1fr;
  }

  .AboutUs_building--about-stripes__12dJy {
    -webkit-transform: skewY(20deg) translate(-50%, -500px);
            transform: skewY(20deg) translate(-50%, -500px);
  }

  .AboutUs_building--about-grid__-iMww {
    grid-template-columns: 1fr 1fr;
  }

  .AboutUs_investor--about__grid__2wwol {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 720px;
  }

  .AboutUs_join-team--about-stripes__fuF8P {
    -webkit-transform: skewY(20deg) translate(-50%, 1000px);
            transform: skewY(20deg) translate(-50%, 1000px);
  }

  .AboutUs_join-team--about__z90nk {
    padding: 200px 0 200px;
  }
}

@media (min-width: 992px) {
  .AboutUs_building--about-grid__-iMww {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .AboutUs_join-team--about__z90nk {
    padding: 388px 0 200px;
  }

  .AboutUs_join-team--about-stripes__fuF8P {
    -webkit-transform: skewY(20deg) translate(-50%, 880px);
            transform: skewY(20deg) translate(-50%, 880px);
  }

  .AboutUs_join-team--about__list__3L3x6 {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 24px 24px 24px 48px !important;
  }

  .AboutUs_join-team--about__list__3L3x6 h2 {
    margin-bottom: 0 !important;
  }
}
.Research_hero--research__3MaUb {
  min-height: 860px;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  position: relative;
}

.Research_hero--research-stripes__2qX0Y {
  position: absolute;
  z-index: 1;
  -webkit-transform: skewY(-20deg) translateY(-1240px);
          transform: skewY(-20deg) translateY(-1240px);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 1920px;
  background-image: linear-gradient(60deg, #5EE3AE 10%, #46A8F3 70%) !important;
}

.Research_hero--research-stripes-inner__2IcwQ {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Research_hero--research-stripe-1__3iFS9 {
  position: absolute;
  bottom: 700px;
  left: -22px;
  width: 330px;
  height: 105px;
  opacity: .5;
}

.Research_hero--research-stripe-1__3iFS9:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.Research_hero--research-stripe-1__3iFS9:after {
  content: '';
  position: absolute;
  top: 30px;
  left: -46px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.Research_hero--research-stripe-2__2OZvU {
  position: absolute;
  bottom: 618px;
  left: -121px;
  width: 354px;
  height: 82px;
  opacity: .5;
}

.Research_hero--research-stripe-2__2OZvU:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.Research_hero--research-stripe-2__2OZvU:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 69px;
  width: 148px;
  height: 55px;
  background-color: #fafcfd;
  opacity: .3;
}

.Research_hero--research-stripe-3__1qcOX {
  position: absolute;
  bottom: 134px;
  left: -143px;
  width: 248px;
  height: 92px;
  opacity: .7;
}

.Research_hero--research-stripe-3__1qcOX:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .5;
}

.Research_hero--research-stripe-3__1qcOX:after {
  content: '';
  position: absolute;
  bottom: -74px;
  left: 106px;
  width: 198px;
  height: 74px;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .5;
}

.Research_hero--research-stripe-4__1q9EQ {
  position: absolute;
  bottom: 386px;
  left: 50%;
  -webkit-transform: translateX(calc(-50% - 80px));
          transform: translateX(calc(-50% - 80px));
  width: 354px;
  height: 130px;
  opacity: .5;
  background-color: #5ADABA;
  opacity: .5;
}

.Research_hero--research-stripe-5__2IltK {
  position: absolute;
  z-index: 1;
  right: 454px;
  bottom: -25px;
  width: 255px;
  height: 94px;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

.Research_hero--research-stripe-5__2IltK:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.Research_hero--research-stripe-5__2IltK:after {
  content: '';
  position: absolute;
  top: 0;
  left: -86px;
  width: 478px;
  height: 1px;
  background-color: #FEFEFE;
}

.Research_hero--research-stripe-6__16gY5 {
  position: absolute;
  z-index: 0;
  right: -113px;
  bottom: -80px;
  width: 613px;
  height: 225px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .7;
}

.Research_hero--research-stripe-7__1ppx4 {
  position: absolute;
  z-index: 0;
  right: -170px;
  bottom: 242px;
  width: 354px;
  height: 90px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  opacity: .2;
}

.Research_hero--research-stripe-8__161Gb {
  position: absolute;
  bottom: 212px;
  right: 86px;
  width: 148px;
  height: 54px;
  opacity: .5;
}

.Research_hero--research-stripe-8__161Gb:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fafcfd;
  opacity: .2;
}

.Research_hero--research-stripe-8__161Gb:after {
  content: '';
  position: absolute;
  top: 0;
  right: -22px;
  width: 276px;
  height: 1px;
  background-color: #fafcfd;
}

.Research_hero--research-stripe-9__20XRo {
  position: absolute;
  bottom: -452px;
  right: -58px;
  width: 354px;
  height: 130px;
}

.Research_hero--research-stripe-9__20XRo:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .3;
}

.Research_hero--research-stripe-9__20XRo:after {
  content: '';
  position: absolute;
  top: -40px;
  right: -54px;
  width: 283px;
  height: 105px;
  background-image: linear-gradient(45deg, #88AFFF, #CE90FF);
  opacity: .7;
}

.Research_hero--technology__vF5Hh {
  margin-top: 320px !important;
}

.Research_hero--technology-stripes__1wp6K {
  position: absolute;
  z-index: 1;
  -webkit-transform: skewY(20deg) translateY(0);
          transform: skewY(20deg) translateY(0);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 800px;
  background-image: linear-gradient(200deg, rgb(216, 89, 113) 0%, #FFB16B 100%) !important;
}

.Research_hero--technology-stripes-inner__3Xi3_ {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Research_hero--technology-stripe-1__QPY46 {
  position: absolute;
  z-index: 1;
  top: -650px;
  left: -170px;
  width: 248px;
  height: 92px;
}

.Research_hero--technology-stripe-1__QPY46:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Research_hero--technology-stripe-1__QPY46:after {
  content: '';
  position: absolute;
  top: -48px;
  right: -85px;
  width: 198px;
  height: 72px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Research_hero--technology-stripe-2__3Q58j {
  position: absolute;
  z-index: 0;
  top: -660px;
  left: -869px;
  width: 1080px;
  height: 140px;
  background-image: linear-gradient(45deg, #FFB3B7, #FE7C81);
  opacity: .4;
}

.Research_hero--technology-stripe-3__Ah5CY {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -240px;
  width: 614px;
  height: 226px;
}

.Research_hero--technology-stripe-3__Ah5CY:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #E2F8FF);
  opacity: .2;
}

.Research_hero--technology-stripe-3__Ah5CY:after {
  content: '';
  position: absolute;
  top: 226px;
  right: 209px;
  width: 256px;
  height: 94px;
  background-color: #fafcfd;
  opacity: .2;
}

.Research_hero--technology-stripe-4__2EBXU {
  position: absolute;
  z-index: 1;
  bottom: -80px;
  left: -1230px;
  width: 1512px;
  height: 300px;
}

.Research_hero--technology-stripe-4__2EBXU:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.Research_hero--technology-stripe-4__2EBXU:after {
  content: '';
  position: absolute;
  bottom: -62px;
  right: -230px;
  width: 1080px;
  height: 144px;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.Research_hero--technology-stripe-5__xp5-l {
  position: absolute;
  z-index: 0;
  right: -16px;
  bottom: 0;
  width: 255px;
  height: 94px;
  background-color: #FAFCFD;
  opacity: .3;
}

.Research_hero--technology-stripe-6__45eCM {
  position: absolute;
  z-index: 0;
  right: -192px;
  top: -255px;
  width: 255px;
  height: 255px;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  -webkit-transform: skewx(-56deg);
          transform: skewx(-56deg);
  opacity: .3;
}

/* Hero content */
.Research_hero__content--research__1Ebjs {
  width: 100%;
  max-width: 1164px;
  margin: 162px auto 0 !important;
}

.Research_hero__text--research__2vcSj {
  width: 100%;
  padding: 60px 0 0;
}

.Research_hero__button--research__2HVYc {
  margin: 48px 0 0 !important;
}

.Research_hero__image--research__3ONnY {
  position: relative;
  width: 544px;
}

.Research_hero__image--research__3ONnY img {
  position: relative;
  top: -18px;
  left: -80px;
  display: block;
  width: 691px;
  max-width: 691px;
}

/* World class team */
.Research_team--research__13K7f {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 120px !important;
  padding-bottom: 116px;
}

.Research_team--research__13K7f:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% - 64px);
  height: 1px;
  background-color: #d8d8d8;
}

.Research_team--research-stripes__9uYFG {
  position: absolute;
  z-index: 1;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 100%;
}

.Research_team--research-stripes-inner__3G8Pi {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Research_team--research-stripe-1__3BOpF {
  position: absolute;
  right: -175px;
  bottom: -160px;
  width: 330px;
  height: 105px;
}

.Research_team--research-stripe-1__3BOpF:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .75;
}

.Research_team--research-stripe-1__3BOpF:after {
  content: '';
  position: absolute;
  top: 15px;
  left: -94px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.Research_team--research-stripe-2__1lfvf {
  position: absolute;
  right: -138px;
  bottom: -240px;
  width: 354px;
  height: 82px;
}

.Research_team--research-stripe-2__1lfvf:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .2;
}

.Research_team--research-stripe-2__1lfvf:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 29px;
  width: 148px;
  height: 55px;
  background-color: #fafcfd;
  opacity: .3;
}

.Research_team__wrapper--research__3VYUH {
  grid-template-columns: 100%;
}

.Research_team__image--research__34U54 {
  position: relative;
  left: -87px;
  width: 474px;
  height: 321px;
  padding: 65px 0 0;
}

.Research_team__image--research__34U54 img {
  display: block;
  position: relative;
  top: -19px;
  left: -31px;
  width: 622px;
  max-width: 622px;
}

.Research_team__text--research__YfgQg {
  padding: 0;
}

.Research_team__text--research__YfgQg h2 {
  font-size: 36px;
  line-height: 50px;
  color: #131415;
  max-width: 353px;
}

.Research_team__desc--research__3hvLr {
  margin: 24px 0 0 !important;
  font-size: 17px;
  line-height: 32px;
  color: #7b7b7b;
}

.Research_team__button--research__3cqGZ {
  margin: 40px 0 0 !important;
}

/* Research lead */
.Research_lead--research__Yjn9H {
  margin-bottom: 102px !important;
}

.Research_lead__title--research__3cPpJ {
  max-width: 460px;
  margin: 0 auto 66px !important;
  font-size: 36px !important;
  line-height: 49px;
  text-align: center;
}

.Research_lead__text--research__G7mnF img {
  display: block;
  max-width: 114px;
  border-radius: 50%;
  margin: 0 auto 40px;
}

.Research_lead__text--research__G7mnF h2 {
  margin: 0 0 24px;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}

.Research_lead__desc--research__1kCYC {
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  color: #737b8b;
}

.Research_count--technology__3_ZPJ {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

/* Data science */
.Research_data__wrapper--research__92q6I {
  grid-template-columns: 100%;
}

.Research_data__image--research__1vn6v {
  position: relative;
  left: -20px;
  width: 510px;
  height: 363px;
}

.Research_data__image--research__1vn6v img {
  display: block;
  position: relative;
  top: -18px;
  left: -80px;
  bottom: -142px;
  width: 622px;
  max-width: 622px;
}

.Research_data__text--research__lEyNl {
  padding: 0;
}

.Research_data__text--research__lEyNl h2 {
  max-width: 340px;
  font-size: 36px;
  line-height: 50px;
}

.Research_data__desc--research__1119h {
  margin: 24px 0 0;
  font-size: 17px;
  font-weight: normal;
  line-height: 32px;
}

.Research_data__button--research__TydLc {
  margin: 40px 0 0 !important;
}

/* Ecological research */
.Research_ecological--research__2zirw {
  margin-top: 200px !important;
}

.Research_ecological--research__grid__1hjQR {
  padding: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 32px;
}

.Research_ecological--research__grid-desktop__2z5Ek {
  display: none;
}

.Research_ecological--research__grid-mobile__25OA8 {
  display: block;
  margin: 40px 0 0;
}

.Research_tab--research__1ebKO {
  margin-top: 46px;
}

/* Research Evolve */
.Research_evolve--research__22H_6 {
  margin-top: 152px !important;
  margin-bottom: 152px !important;
}

.Research_evolve__image--research__3WLFL {
  display: block;
  max-width: 292px !important;
  margin: 75px auto 0 !important;
}

/* Responsive */

@media (min-width: 768px) {
  .Research_hero--research__3MaUb {
    min-height: 939px;
  }

  .Research_hero__content--research__1Ebjs {
    margin: 204px auto 0 !important;
  }

  .Research_hero--technology-stripes__1wp6K {
    -webkit-transform: skewY(20deg) translateY(-50px);
            transform: skewY(20deg) translateY(-50px);
  }

  .Research_ecological--research__2zirw {
    margin-top: 400px !important;
  }

  .Research_ecological--research__grid__1hjQR {
    padding: 40px;
  }
  .Research_count--technology__3_ZPJ {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .Research_ecological--research__grid__1hjQR {
    grid-template-columns: 360px auto;
  }
  .Research_evolve--research__22H_6 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .Research_hero__text--research__2vcSj {
    width: 333px;
    padding: 60px 0 0;
  }

  .Research_team__text--research__YfgQg {
    padding: 0 0 0 56px;
  }

  .Research_team__wrapper--research__3VYUH {
    grid-template-columns: 474px calc(100% - 474px);
  }

  .Research_data__wrapper--research__92q6I {
    grid-template-columns: 490px calc(100% - 490px);
  }

  .Research_data__text--research__lEyNl {
    padding: 0 0 0 78px;
  }

  .Research_ecological--research__grid__1hjQR {
    padding: 80px;
  }
}

@media (min-width: 1200px) {
  .Research_ecological--research__grid-desktop__2z5Ek {
    display: block;
  }
  
  .Research_ecological--research__grid-mobile__25OA8 {
    display: none;
  }
}
/* Hero Technology */
.Technology_hero--technology__3rTF3 {
  min-height: 1024px;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  position: relative;
  overflow: visible !important;
  overflow: initial !important;
}

.Technology_hero--technology-stripes__3sxFQ {
  position: absolute;
  z-index: 0;
  -webkit-transform: skewY(20deg) translate(0px, -1062px);
          transform: skewY(20deg) translate(0px, -1062px);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 1920px;
  background: linear-gradient(90deg, #FFB16B, #FF6E89) !important;
}

.Technology_hero--technology-stripes-inner__2kbNH {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Technology_hero--technology-stripe-1__NWT0Z {
  position: absolute;
  bottom: 344px;
  left: -192px;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #ffffff, rgba(255, 255, 255, 0));
  opacity: .2;
}

.Technology_hero--technology-stripe-2__ls-57 {
  position: absolute;
  right: -236px;
  bottom: 455px;
  width: 1004px;
  height: 368px;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .2;
}

.Technology_hero--technology-stripe-3__3UW6O {
  position: absolute;
  left: 238px;
  bottom: 360px;
  width: 354px;
  height: 128px;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .2;
}

.Technology_hero--technology-stripe-4__1l3lb {
  position: absolute;
  bottom: 96px;
  left: 550px;
  width: 354px;
  height: 80px;
}

.Technology_hero--technology-stripe-4__1l3lb:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #ffffff, rgba(255, 255, 255, 0));
  opacity: .1;
}

.Technology_hero--technology-stripe-4__1l3lb:after {
  content: '';
  position: absolute;
  top: -46px;
  right: 8px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .06;
}

.Technology_hero--technology-stripe-5__1ONei {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 354px;
  height: 90px;
}

.Technology_hero--technology-stripe-5__1ONei:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .5;
}

.Technology_hero--technology-stripe-5__1ONei:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 60px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.Technology_hero--technology-stripe-6__2u58C {
  position: absolute;
  bottom: 400px;
  left: 537px;
  width: 148px;
  height: 54px;
}

.Technology_hero--technology-stripe-6__2u58C:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .2;
}

.Technology_hero--technology-stripe-6__2u58C:after {
  content: '';
  position: absolute;
  top: 0;
  left: -42px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .5;
}

.Technology_hero--technology-stripe-7__1-2-Q {
  position: absolute;
  bottom: -66px;
  left: -72px;
  width: 284px;
  height: 104px;
}

.Technology_hero--technology-stripe-7__1-2-Q:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .5;
}

.Technology_hero--technology-stripe-7__1-2-Q:after {
  content: '';
  position: absolute;
  top: 0;
  right: -102px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.Technology_handling--technology__grid__1kG3F {
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  row-gap: 32px;
}

/* Get Bottom Technology */
.Technology_get-bottom--technology__2jk0W {
  position: relative;
  margin: 135px auto 0 !important;
}

.Technology_get-bottom--technology__grid__1O9vt {
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  row-gap: 32px;
}

.Technology_get-bottom--technology-stripes__2ib-q {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: skewY(-20deg) translate(-50%, -50%);
          transform: skewY(-20deg) translate(-50%, -50%);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100vw;
  height: 600px;
}

.Technology_get-bottom--technology-stripes-inner__BLPp2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Technology_get-bottom--technology-stripe-1__1qfjw {
  position: absolute;
  top: -198px;
  left: -168px;
  width: 284px;
  height: 104px;
}

.Technology_get-bottom--technology-stripe-1__1qfjw:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.Technology_get-bottom--technology-stripe-1__1qfjw:after {
  content: '';
  position: absolute;
  bottom: -82px;
  left: -114px;
  width: 354px;
  height: 82px;
  background-image: linear-gradient(120deg, #82F9C9, #46A8F3);
  opacity: .2;
}

.Technology_get-bottom--technology-stripe-2__3mRmf {
  position: absolute;
  top: 0;
  right: -829px;
  width: 1080px;
  height: 140px;
  background-image: linear-gradient(180deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.Technology_get-bottom--technology-stripe-3__2R9kY {
  position: absolute;
  right: -90px;
  top: 777px;
  width: 354px;
  height: 80px;
}

.Technology_get-bottom--technology-stripe-3__2R9kY:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #9BE5FF, #8994FF);
  opacity: .3;
}

.Technology_get-bottom--technology-stripe-3__2R9kY:after {
  content: '';
  position: absolute;
  top: -30px;
  left: 125px;
  width: 354px;
  height: 76px;
  background-image: linear-gradient(120deg, #88AFFF, #CE90FF);
  opacity: .7;
}

.Technology_get-bottom--technology__content__27Dsr {
  position: relative;
  z-index: 1;
}

.Technology_get-bottom--technology__text__2VNug {
  max-width: 100%;
  text-align: center;
}

/* Security Technology */
.Technology_security--technology__27qJA {
  position: relative;
  padding: 372px 0 225px;
}

.Technology_security--technology-stripes__2EScy {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: skewY(20deg) translate(-50%, -50%);
          transform: skewY(20deg) translate(-50%, -50%);
  overflow: visible !important;
  overflow: initial !important;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100vw;
  height: 860px;
  background: linear-gradient(160deg, #46A8F3, #5EE3AE) !important;
}

.Technology_security--technology-stripes-inner__23_ds {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Technology_security--technology-stripe-1__1ZtzZ {
  position: absolute;
  left: 68px;
  top: -48px;
  width: 354px;
  height: 125px;
}

.Technology_security--technology-stripe-1__1ZtzZ:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.Technology_security--technology-stripe-1__1ZtzZ:after {
  content: '';
  position: absolute;
  top: 40px;
  right: -92px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.Technology_security--technology-stripe-2__1vTN4 {
  position: absolute;
  right: -22px;
  top: -28px;
  width: 354px;
  height: 80px;
}

.Technology_security--technology-stripe-2__1vTN4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.Technology_security--technology-stripe-2__1vTN4:after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 150px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.Technology_security--technology-stripe-3__6hZCW {
  position: absolute;
  left: -348px;
  bottom: 0;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.Technology_security--technology-stripe-4__1Qq73 {
  position: absolute;
  left: -14px;
  bottom: -50px;
  width: 440px;
  height: 160px;
}

.Technology_security--technology-stripe-4__1Qq73:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.Technology_security--technology-stripe-4__1Qq73:after {
  content: '';
  position: absolute;
  top: 0;
  left: -185px;
  width: 824px;
  height: 1px;
  background-color: #FAFCFD;
}

.Technology_security--technology-stripe-5__jj9QP {
  position: absolute;
  right: 168px;
  bottom: -50px;
  width: 282px;
  height: 82px;
}

.Technology_security--technology-stripe-5__jj9QP:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.Technology_security--technology-stripe-5__jj9QP:after {
  content: '';
  position: absolute;
  top: -30px;
  right: -12px;
  width: 148px;
  height: 54px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.Technology_security--technology__content__3rsqE {
  position: relative;
  z-index: 1;
}

.Technology_security--technology__content-title__pyv6o {
  margin: 0;
  font-size: 36px;
  line-height: 50px;
  color: #ffffff;
}

.Technology_security--technology__content-subtitle__3kxYQ {
  max-width: 443px;
  margin: 16px auto 0 !important;
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
}

.Technology_security--technology__content-grid__34DLQ {
  grid-template-columns: 1fr;
  max-width: 890px;
  margin: 45px auto 0 !important;
}

/* Security Technology */
.Technology_action--technology__3Rh0M {
  position: relative;
  z-index: 2 !important;
}

.Technology_action--technology-title__1AQ0m {
  max-width: 433px;
  font-size: 36px !important;
  line-height: 50px;
}

.Technology_action--technology-grid__1Ek2g {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  row-gap: 24px;
  width: 100%;
  margin: 40px 0 0 !important;
}

.Technology_action--technology-box__3QwtX {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 10px;
}

.Technology_action--technology--image__3nSo- {
  position: relative;
}

.Technology_action--technology--image__3nSo- img {
  position: relative;
  top: -19px;
  left: -32px
}

/* Responsive */

@media (min-width: 576px) {
  .Technology_get-bottom--technology__grid__1O9vt {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .Technology_handling--technology__grid__1kG3F {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .Technology_get-bottom--technology__text__2VNug {
    max-width: 372px;
    text-align: left;
  }
  
  .Technology_hero--technology__title__2Y1kL {
    max-width: 440px;
  }
  
  .Technology_security--technology-stripes__2EScy {
    height: 600px;
  }

  .Technology_security--technology__content-grid__34DLQ {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .Technology_get-bottom--technology__grid__1O9vt {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .Technology_action--technology-grid__1Ek2g {
    width: 512px;
  }
}
.Vision_hero--vision__1auwc {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  position: relative;
}

.Vision_hero--vision-stripes__1yzGb {
  position: absolute;
  z-index: 0;
  -webkit-transform: skewY(20deg) translate(0px, -1280px);
          transform: skewY(20deg) translate(0px, -1280px);
  overflow: visible !important;
  overflow: initial !important;
  width: 100%;
  height: 1920px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: linear-gradient(90deg, #DB67FF 0%, #769EFF 100%) !important;
}

.Vision_hero--vision-stripes-inner__7lt0N {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Vision_hero--vision-stripe-1__1BkNu {
  position: absolute;
  bottom: 370px;
  left: -137px;
  width: 354px;
  height: 130px;
}

.Vision_hero--vision-stripe-1__1BkNu:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.Vision_hero--vision-stripe-1__1BkNu:after {
  content: '';
  position: absolute;
  right: -88px;
  bottom: -26px;
  width: 142px;
  height: 52px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.Vision_hero--vision-stripe-2__3Pdzp {
  position: absolute;
  bottom: 0;
  left: 154px;
  width: 198px;
  height: 72px;
}

.Vision_hero--vision-stripe-2__3Pdzp:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Vision_hero--vision-stripe-2__3Pdzp:after {
  content: '';
  position: absolute;
  top: 40px;
  left: 8px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.Vision_hero--vision-stripe-3__1w16K {
  position: absolute;
  bottom: -64px;
  left: 68px;
  width: 248px;
  height: 92px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Vision_hero--vision-stripe-4__29e2a {
  position: absolute;
  right: 213px;
  bottom: 430px;
  width: 148px;
  height: 54px;
}

.Vision_hero--vision-stripe-4__29e2a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.Vision_hero--vision-stripe-4__29e2a:after {
  content: '';
  position: absolute;
  top: 24px;
  left: 0;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .5;
}

.Vision_hero--vision-stripe-5__2fSJT {
  position: absolute;
  right: 108px;
  bottom: 460px;
  width: 354px;
  height: 128px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .4;
}

.Vision_hero--vision-stripe-6__PlsnJ {
  position: absolute;
  right: -12px;
  bottom: 492px;
  width: 148px;
  height: 54px;
}

.Vision_hero--vision-stripe-6__PlsnJ:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .1;
}

.Vision_hero--vision-stripe-6__PlsnJ:after {
  content: '';
  position: absolute;
  top: 0;
  left: -62px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.Vision_hero--vision-stripe-7__3BkOq {
  position: absolute;
  right: -181px;
  bottom: 0;
  width: 764px;
  height: 280px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.Vision_hero--vision-stripe-8__7DjVB {
  position: absolute;
  right: -25px;
  bottom: -40px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.Vision_hero--vision-stripe-9__2V3Aj {
  position: absolute;
  right: 70px;
  bottom: 50px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.Vision_hero--vision-stripe-10__1eyrK {
  position: absolute;
  right: 200px;
  bottom: 0;
  width: 148px;
  height: 50px;
}

.Vision_hero--vision-stripe-10__1eyrK:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #C682FE, #A2A7FB);
  opacity: .7;
}

.Vision_hero--vision-stripe-10__1eyrK:after {
  content: '';
  position: absolute;
  top: 25px;
  left: -45px;
  width: 227px;
  height: 1px;
  background-color: #ffffff;
}

.Vision_hero--vision-stripe-11__2VOZJ {
  position: absolute;
  bottom: -420px;
  left: -114px;
  width: 248px;
  height: 90px;
}

.Vision_hero--vision-stripe-11__2VOZJ:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Vision_hero--vision-stripe-11__2VOZJ:after {
  content: '';
  position: absolute;
  top: -44px;
  left: -16px;
  width: 198px;
  height: 74px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.Vision_hero--vision-box-text__2fJV7 {
  padding: 40px;
  background-color: #ffffff;
}

/* Prepare vision */
.Vision_prepare--vision__1DnQg {
  padding-top: 118px;
  grid-row-gap: 64px;
  row-gap: 64px;
}

.Vision_prepare--vision-wrapper__1el8X {
  position: relative;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 22px 60px 0 rgba(24, 50, 92, .1);
}

.Vision_prepare--vision-wrapper__1el8X img {
  position: absolute;
  top: -42px;
  right: 40px;
  max-width: 85px;
}

.Vision_prepare--vision-title__3bijZ {
  font-size: 20px;
  line-height: 26px;
}

.Vision_prepare--vision-desc__3_YA3 {
  margin-top: 10px !important;
  font-size: 17px;
  line-height: 27px;
  color: #737B8B;
}

/* Impact vision */
.Vision_impact--vision__3Obz4 {
  position: relative;
  z-index: 2 !important;
  padding: 175px 0 25px;
}

.Vision_impact--vision-wrapper__2U68M + .Vision_impact--vision-wrapper__2U68M {
  border-top: 1px solid #F3F3F5;
  margin-top: 80px;
  padding-top: 80px;
}

.Vision_impact--vision-title__3mx8k {
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 22px !important;
  line-height: 36px;
  text-align: center;
}

.Vision_impact--vision-box__eqFlZ {
  margin-top: 48px !important;
}

.Vision_impact--vision-text__1DIPI {
  font-style: 17px;
  line-height: 30px;
  color: #737B8B;
}

/* Starting vision */
.Vision_start--vision__1EH-h {
  position: relative;
  z-index: 1;
  margin-bottom: 170px !important;
  padding: 150px 0 100px
}

.Vision_start--vision-content__IOFsy {
  z-index: 1;
}

.Vision_start--vision-content__IOFsy img {
  display: block;
  margin: 0 auto 48px;
  max-width: 114px;
}

.Vision_start--vision-title__1guag {
  max-width: 886px;
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 24px !important;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
}

.Vision_start--vision-text__Lx2uD {
  max-width: 886px;
  margin-top: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 24px !important;
  line-height: 37px;
  text-align: center;
  font-style: italic;
  color: #ffffff;
}

.Vision_start--vision-stripes__nPR1M {
  position: absolute;
  z-index: 0;
  left: 50%;
  -webkit-transform: skewY(-20deg) translate(-50%, -60px);
          transform: skewY(-20deg) translate(-50%, -60px);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  width: 100vw;
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: linear-gradient(45deg, #0EE8F0 0%, #9F26F1 120%) !important;
}

.Vision_start--vision-stripes-inner__QtbJ_ {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.Vision_start--vision-stripe-1__2uuve {
  position: absolute;
  top: 40px;
  left: 0;
  width: 148px;
  height: 54px;
}

.Vision_start--vision-stripe-1__2uuve:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .1;
}

.Vision_start--vision-stripe-1__2uuve:after {
  content: '';
  position: absolute;
  top: 0;
  left: -48px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.Vision_start--vision-stripe-2__-BT9O {
  position: absolute;
  top: 0;
  left: 120px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.Vision_start--vision-stripe-3__2oh_T {
  position: absolute;
  top: 0;
  right: -511px;
  width: 764px;
  height: 280px;
}

.Vision_start--vision-stripe-3__2oh_T:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .1;
}

.Vision_start--vision-stripe-3__2oh_T:after {
  content: '';
  position: absolute;
  top: -60px;
  left: -288px;
  width: 764px;
  height: 162px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .1;
}

.Vision_start--vision-stripe-4__9xkG2 {
  position: absolute;
  top: 300px;
  left: 540px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .12;
}

/* Responsive */

@media (min-width: 768px) {
  .Vision_hero--vision-box-text__2fJV7 {
    padding: 60px 70px;
  }

  .Vision_start--vision__1EH-h {
    margin-bottom: 0 !important;
  }

  .Vision_start--vision-stripes__nPR1M {
    height: 920px;
    -webkit-transform: skewY(-20deg) translate(-50%, -120px);
            transform: skewY(-20deg) translate(-50%, -120px);
  }
}
.ContactUs_hero--contact__3FJ0O {
  margin: 0 auto !important;
  padding-top: 120px;
  padding-bottom: 120px;
}

.ContactUs_hero--contact-stripes__3JKRb {
  position: absolute;
  overflow: visible;
  overflow: initial;
  z-index: 1;
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
  background: linear-gradient(228deg, #FF6E89 10%, #FFB16B 80%) !important;
  -webkit-transform: skewY(-20deg);
          transform: skewY(-20deg);
  -webkit-transform-origin: 0;
          transform-origin: 0;
}

.ContactUs_hero--contact-stripes-inner__mWXEL {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  max-width: 1440px;
  height: 100%;
}

.ContactUs_hero--contact-stripe__3ocyB {
  position: absolute;
}

.ContactUs_hero--contact-stripe-1__1NEsC {
  position: absolute;
  z-index: 1;
  bottom: 588px;
  left: -400px;
  width: 524px;
  height: 198px;
}

.ContactUs_hero--contact-stripe-1__1NEsC:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(217deg,  #ffffff 0%,  rgba(255, 255, 255, 0) 100%);
  opacity: .2;
}

.ContactUs_hero--contact-stripe-1__1NEsC:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 0;
  width: calc(100% + 50px);
  height: 1px;
  background-color: #ffffff;
}

.ContactUs_hero--contact-stripe-2__gBhCV {
  position: absolute;
  z-index: 1;
  right: -266px;
  bottom: 280px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(217deg,  rgba(255,110,137,0.4) 0%,  rgba(255,177,107,0.63) 100%);
}

.ContactUs_hero--contact-stripe-2__gBhCV:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -44px;
  left: 17px;
  width: 148px;
  height: 64px;
  background-color: #fafcfd;
  opacity: .1;
}

.ContactUs_hero--contact-stripe-2__gBhCV:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 20px;
  left: -95px;
  width: 260px;
  height: 1px;
  background-color: #ffffff;
}

.ContactUs_hero--contact-stripe-3__35Tit {
  position: absolute;
  z-index: 1;
  right: -424px;
  bottom: -80px;
  width: 1004px;
  height: 80px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.27) 100%);
}

.ContactUs_hero--contact-stripe-4__1_gZ8 {
  position: absolute;
  z-index: 1;
  left: 152px;
  bottom: 0;
  width: 354px;
  height: 100px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.63) 100%);
}

.ContactUs_hero--contact-stripe-4__1_gZ8:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 20px;
  width: 260px;
  height: 1px;
  background-color: #ffffff;
}

.ContactUs_hero--contact-stripe-5__1wzXK {
  position: absolute;
  z-index: 2;
  left: 180px;
  bottom: -30px;
  width: 148px;
  height: 60px;
  background-color: #fafcfd;
  opacity: .3;
}

.ContactUs_hero--contact-stripe-6__Ulxzc {
  position: absolute;
  z-index: 1;
  left: 102px;
  bottom: -80px;
  width: 354px;
  height: 80px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.63) 100%);
}

.ContactUs_hero--contact-form__1KjoE {
  width: 100%;
  max-width: 1164px;
  padding: 0 32px;
}

.ContactUs_contact--input__2WZYu,
.ContactUs_contact--textarea__2uoUs {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  height: 70px;
  border: 0;
  border-radius: 4px;
  padding: 26px 30px 26px 55px;
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, .1);
}

.ContactUs_contact--textarea__2uoUs {
  height: 180px;
  resize: none;
}

.ContactUs_contact--input__2WZYu:focus,
.ContactUs_contact--textarea__2uoUs:focus {
  outline: none;
}

.ContactUs_contact--input__2WZYu:focus + .ContactUs_contact--form-icon__2qGHb img,
.ContactUs_contact--textarea__2uoUs:focus + .ContactUs_contact--form-icon__2qGHb img {
  opacity: 1;
}

.ContactUs_contact--form-icon__2qGHb {
  position: absolute;
  z-index: 1;
  top: 26px;
  left: 24px;
  width: 18px;
  height: 18px;
  text-align: center;
}

.ContactUs_contact--form-icon__2qGHb img {
  height: 18px;
  opacity: .5;
}

.ContactUs_contact--button__15WRu {
  cursor: pointer;
  width: 100%;
  height: 65px;
  margin-right: 16px;
  color: #fff !important;
  background-color: #aa63ff !important;
  box-shadow: 0 8px 26px 0 rgba(170, 99, 255, 0.30);
}

.ContactUs_contact--form-separator__3jTVK {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.ContactUs_contact--grid__1G-x5 {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: auto;
}

.ContactUs_contact--form-title__X6HbI {
  grid-column-start: 1;
  grid-row-start: 1;
  padding: 0;
}

.ContactUs_contact--form-alternative__3uXAc {
  grid-column-start: 1;
  grid-row-start: 3;
  margin-top: 60px;
  padding: 0 0 80px;
}

.ContactUs_contact--form-wrapper__2NMYU {
  grid-column-start: 1;
  grid-row-start: 2;
  margin-top: 60px;
  padding: 0;
}

.ContactUs_contact--social-list__1T3YW {
  margin: 8px 0 0;
  padding: 0;
  list-style: none;
}

.ContactUs_contact--social-list__1T3YW a + a {
  margin-left: 24px;
}

.ContactUs_contact--social-list__1T3YW a img {
  height: 28px;
}

/* Responsive */

@media (min-width: 768px) {
  .ContactUs_hero--contact__3FJ0O {
    padding-top: 240px;
    padding-bottom: 240px;
  }
  
  .ContactUs_hero--contact-stripe-1__1NEsC {
    left: -200px;
  }

  .ContactUs_hero--contact-stripe-2__gBhCV {
    right: -66px;
  }
  
  .ContactUs_contact--grid__1G-x5 {
    display: grid;
    grid-gap: 0;
    grid-template-columns: calc(100% - 360px) 360px;
    grid-template-rows: auto 1fr;
  }
  
  .ContactUs_contact--form-title__X6HbI {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  
  .ContactUs_contact--form-alternative__3uXAc {
    grid-column-start: 1;
    grid-row-start: 2;
    margin-top: 120px;
    padding: 0 60px 0 0;
  }
  
  .ContactUs_contact--form-wrapper__2NMYU {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .ContactUs_contact--grid__1G-x5 {
    grid-template-columns: calc(100% - 466px) 466px;
  }

  .ContactUs_contact--form-alternative__3uXAc {
    margin-top: 150px;
    padding: 0 112px 0 0;
  }
}

@media (min-width: 1920px) {
  .ContactUs_hero--contact-stripes__3JKRb {
    -webkit-transform: skewY(-20deg);
            transform: skewY(-20deg);
  }
}
