.hero--vision {
  background: initial !important;
  position: relative;
}

.hero--vision-stripes {
  position: absolute;
  z-index: 0;
  transform: skewY(20deg) translate(0px, -1280px);
  overflow: initial !important;
  width: 100%;
  height: 1920px;
  flex: 1;
  background: linear-gradient(90deg, #DB67FF 0%, #769EFF 100%) !important;
}

.hero--vision-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hero--vision-stripe-1 {
  position: absolute;
  bottom: 370px;
  left: -137px;
  width: 354px;
  height: 130px;
}

.hero--vision-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.hero--vision-stripe-1:after {
  content: '';
  position: absolute;
  right: -88px;
  bottom: -26px;
  width: 142px;
  height: 52px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.hero--vision-stripe-2 {
  position: absolute;
  bottom: 0;
  left: 154px;
  width: 198px;
  height: 72px;
}

.hero--vision-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--vision-stripe-2:after {
  content: '';
  position: absolute;
  top: 40px;
  left: 8px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.hero--vision-stripe-3 {
  position: absolute;
  bottom: -64px;
  left: 68px;
  width: 248px;
  height: 92px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--vision-stripe-4 {
  position: absolute;
  right: 213px;
  bottom: 430px;
  width: 148px;
  height: 54px;
}

.hero--vision-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--vision-stripe-4:after {
  content: '';
  position: absolute;
  top: 24px;
  left: 0;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .5;
}

.hero--vision-stripe-5 {
  position: absolute;
  right: 108px;
  bottom: 460px;
  width: 354px;
  height: 128px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .4;
}

.hero--vision-stripe-6 {
  position: absolute;
  right: -12px;
  bottom: 492px;
  width: 148px;
  height: 54px;
}

.hero--vision-stripe-6:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .1;
}

.hero--vision-stripe-6:after {
  content: '';
  position: absolute;
  top: 0;
  left: -62px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.hero--vision-stripe-7 {
  position: absolute;
  right: -181px;
  bottom: 0;
  width: 764px;
  height: 280px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.hero--vision-stripe-8 {
  position: absolute;
  right: -25px;
  bottom: -40px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.hero--vision-stripe-9 {
  position: absolute;
  right: 70px;
  bottom: 50px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--vision-stripe-10 {
  position: absolute;
  right: 200px;
  bottom: 0;
  width: 148px;
  height: 50px;
}

.hero--vision-stripe-10:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #C682FE, #A2A7FB);
  opacity: .7;
}

.hero--vision-stripe-10:after {
  content: '';
  position: absolute;
  top: 25px;
  left: -45px;
  width: 227px;
  height: 1px;
  background-color: #ffffff;
}

.hero--vision-stripe-11 {
  position: absolute;
  bottom: -420px;
  left: -114px;
  width: 248px;
  height: 90px;
}

.hero--vision-stripe-11:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--vision-stripe-11:after {
  content: '';
  position: absolute;
  top: -44px;
  left: -16px;
  width: 198px;
  height: 74px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--vision-box-text {
  padding: 40px;
  background-color: #ffffff;
}

/* Prepare vision */
.prepare--vision {
  padding-top: 118px;
  row-gap: 64px;
}

.prepare--vision-wrapper {
  position: relative;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 22px 60px 0 rgba(24, 50, 92, .1);
}

.prepare--vision-wrapper img {
  position: absolute;
  top: -42px;
  right: 40px;
  max-width: 85px;
}

.prepare--vision-title {
  font-size: 20px;
  line-height: 26px;
}

.prepare--vision-desc {
  margin-top: 10px !important;
  font-size: 17px;
  line-height: 27px;
  color: #737B8B;
}

/* Impact vision */
.impact--vision {
  position: relative;
  z-index: 2 !important;
  padding: 175px 0 25px;
}

.impact--vision-wrapper + .impact--vision-wrapper {
  border-top: 1px solid #F3F3F5;
  margin-top: 80px;
  padding-top: 80px;
}

.impact--vision-title {
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 22px !important;
  line-height: 36px;
  text-align: center;
}

.impact--vision-box {
  margin-top: 48px !important;
}

.impact--vision-text {
  font-style: 17px;
  line-height: 30px;
  color: #737B8B;
}

/* Starting vision */
.start--vision {
  position: relative;
  z-index: 1;
  margin-bottom: 170px !important;
  padding: 150px 0 100px
}

.start--vision-content {
  z-index: 1;
}

.start--vision-content img {
  display: block;
  margin: 0 auto 48px;
  max-width: 114px;
}

.start--vision-title {
  max-width: 886px;
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 24px !important;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
}

.start--vision-text {
  max-width: 886px;
  margin-top: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  font-size: 24px !important;
  line-height: 37px;
  text-align: center;
  font-style: italic;
  color: #ffffff;
}

.start--vision-stripes {
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: skewY(-20deg) translate(-50%, -60px);
  transform-origin: 0% 50%;
  width: 100vw;
  height: 100%;
  flex: 1;
  background: linear-gradient(45deg, #0EE8F0 0%, #9F26F1 120%) !important;
}

.start--vision-stripes-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.start--vision-stripe-1 {
  position: absolute;
  top: 40px;
  left: 0;
  width: 148px;
  height: 54px;
}

.start--vision-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .1;
}

.start--vision-stripe-1:after {
  content: '';
  position: absolute;
  top: 0;
  left: -48px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.start--vision-stripe-2 {
  position: absolute;
  top: 0;
  left: 120px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.start--vision-stripe-3 {
  position: absolute;
  top: 0;
  right: -511px;
  width: 764px;
  height: 280px;
}

.start--vision-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .1;
}

.start--vision-stripe-3:after {
  content: '';
  position: absolute;
  top: -60px;
  left: -288px;
  width: 764px;
  height: 162px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .1;
}

.start--vision-stripe-4 {
  position: absolute;
  top: 300px;
  left: 540px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(90deg, #9BE5FF, #CE90FF);
  opacity: .12;
}

/* Responsive */

@media (min-width: 768px) {
  .hero--vision-box-text {
    padding: 60px 70px;
  }

  .start--vision {
    margin-bottom: 0 !important;
  }

  .start--vision-stripes {
    height: 920px;
    transform: skewY(-20deg) translate(-50%, -120px);
  }
}