/* Hero Technology */
.hero--technology {
  min-height: 1024px;
  background: initial !important;
  position: relative;
  overflow: initial !important;
}

.hero--technology-stripes {
  position: absolute;
  z-index: 0;
  transform: skewY(20deg) translate(0px, -1062px);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 1920px;
  background: linear-gradient(90deg, #FFB16B, #FF6E89) !important;
}

.hero--technology-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hero--technology-stripe-1 {
  position: absolute;
  bottom: 344px;
  left: -192px;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #ffffff, rgba(255, 255, 255, 0));
  opacity: .2;
}

.hero--technology-stripe-2 {
  position: absolute;
  right: -236px;
  bottom: 455px;
  width: 1004px;
  height: 368px;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .2;
}

.hero--technology-stripe-3 {
  position: absolute;
  left: 238px;
  bottom: 360px;
  width: 354px;
  height: 128px;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .2;
}

.hero--technology-stripe-4 {
  position: absolute;
  bottom: 96px;
  left: 550px;
  width: 354px;
  height: 80px;
}

.hero--technology-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #ffffff, rgba(255, 255, 255, 0));
  opacity: .1;
}

.hero--technology-stripe-4:after {
  content: '';
  position: absolute;
  top: -46px;
  right: 8px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .06;
}

.hero--technology-stripe-5 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 354px;
  height: 90px;
}

.hero--technology-stripe-5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .5;
}

.hero--technology-stripe-5:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 60px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.hero--technology-stripe-6 {
  position: absolute;
  bottom: 400px;
  left: 537px;
  width: 148px;
  height: 54px;
}

.hero--technology-stripe-6:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .2;
}

.hero--technology-stripe-6:after {
  content: '';
  position: absolute;
  top: 0;
  left: -42px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .5;
}

.hero--technology-stripe-7 {
  position: absolute;
  bottom: -66px;
  left: -72px;
  width: 284px;
  height: 104px;
}

.hero--technology-stripe-7:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #FF9776, #FFB16B);
  opacity: .5;
}

.hero--technology-stripe-7:after {
  content: '';
  position: absolute;
  top: 0;
  right: -102px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.handling--technology__grid {
  grid-template-columns: 1fr;
  row-gap: 32px;
}

/* Get Bottom Technology */
.get-bottom--technology {
  position: relative;
  margin: 135px auto 0 !important;
}

.get-bottom--technology__grid {
  grid-template-columns: 1fr;
  row-gap: 32px;
}

.get-bottom--technology-stripes {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: skewY(-20deg) translate(-50%, -50%);
  overflow: initial !important;
  transform-origin: 0% 50%;
  flex: 1;
  width: 100vw;
  height: 600px;
}

.get-bottom--technology-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.get-bottom--technology-stripe-1 {
  position: absolute;
  top: -198px;
  left: -168px;
  width: 284px;
  height: 104px;
}

.get-bottom--technology-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.get-bottom--technology-stripe-1:after {
  content: '';
  position: absolute;
  bottom: -82px;
  left: -114px;
  width: 354px;
  height: 82px;
  background-image: linear-gradient(120deg, #82F9C9, #46A8F3);
  opacity: .2;
}

.get-bottom--technology-stripe-2 {
  position: absolute;
  top: 0;
  right: -829px;
  width: 1080px;
  height: 140px;
  background-image: linear-gradient(180deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.get-bottom--technology-stripe-3 {
  position: absolute;
  right: -90px;
  top: 777px;
  width: 354px;
  height: 80px;
}

.get-bottom--technology-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #9BE5FF, #8994FF);
  opacity: .3;
}

.get-bottom--technology-stripe-3:after {
  content: '';
  position: absolute;
  top: -30px;
  left: 125px;
  width: 354px;
  height: 76px;
  background-image: linear-gradient(120deg, #88AFFF, #CE90FF);
  opacity: .7;
}

.get-bottom--technology__content {
  position: relative;
  z-index: 1;
}

.get-bottom--technology__text {
  max-width: 100%;
  text-align: center;
}

/* Security Technology */
.security--technology {
  position: relative;
  padding: 372px 0 225px;
}

.security--technology-stripes {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: skewY(20deg) translate(-50%, -50%);
  overflow: initial !important;
  transform-origin: 0% 50%;
  flex: 1;
  width: 100vw;
  height: 860px;
  background: linear-gradient(160deg, #46A8F3, #5EE3AE) !important;
}

.security--technology-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.security--technology-stripe-1 {
  position: absolute;
  left: 68px;
  top: -48px;
  width: 354px;
  height: 125px;
}

.security--technology-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.security--technology-stripe-1:after {
  content: '';
  position: absolute;
  top: 40px;
  right: -92px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.security--technology-stripe-2 {
  position: absolute;
  right: -22px;
  top: -28px;
  width: 354px;
  height: 80px;
}

.security--technology-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .3;
}

.security--technology-stripe-2:after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 150px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .12;
}

.security--technology-stripe-3 {
  position: absolute;
  left: -348px;
  bottom: 0;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.security--technology-stripe-4 {
  position: absolute;
  left: -14px;
  bottom: -50px;
  width: 440px;
  height: 160px;
}

.security--technology-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.security--technology-stripe-4:after {
  content: '';
  position: absolute;
  top: 0;
  left: -185px;
  width: 824px;
  height: 1px;
  background-color: #FAFCFD;
}

.security--technology-stripe-5 {
  position: absolute;
  right: 168px;
  bottom: -50px;
  width: 282px;
  height: 82px;
}

.security--technology-stripe-5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.security--technology-stripe-5:after {
  content: '';
  position: absolute;
  top: -30px;
  right: -12px;
  width: 148px;
  height: 54px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.security--technology__content {
  position: relative;
  z-index: 1;
}

.security--technology__content-title {
  margin: 0;
  font-size: 36px;
  line-height: 50px;
  color: #ffffff;
}

.security--technology__content-subtitle {
  max-width: 443px;
  margin: 16px auto 0 !important;
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
}

.security--technology__content-grid {
  grid-template-columns: 1fr;
  max-width: 890px;
  margin: 45px auto 0 !important;
}

/* Security Technology */
.action--technology {
  position: relative;
  z-index: 2 !important;
}

.action--technology-title {
  max-width: 433px;
  font-size: 36px !important;
  line-height: 50px;
}

.action--technology-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
  width: 100%;
  margin: 40px 0 0 !important;
}

.action--technology-box {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 10px;
}

.action--technology--image {
  position: relative;
}

.action--technology--image img {
  position: relative;
  top: -19px;
  left: -32px
}

/* Responsive */

@media (min-width: 576px) {
  .get-bottom--technology__grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .handling--technology__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .get-bottom--technology__text {
    max-width: 372px;
    text-align: left;
  }
  
  .hero--technology__title {
    max-width: 440px;
  }
  
  .security--technology-stripes {
    height: 600px;
  }

  .security--technology__content-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .get-bottom--technology__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .action--technology-grid {
    width: 512px;
  }
}