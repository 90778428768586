.hero--contact {
  margin: 0 auto !important;
  padding-top: 120px;
  padding-bottom: 120px;
}

.hero--contact-stripes {
  position: absolute;
  overflow: initial;
  z-index: 1;
  width: 100%;
  flex: 1;
  height: 100%;
  background: linear-gradient(228deg, #FF6E89 10%, #FFB16B 80%) !important;
  transform: skewY(-20deg);
  transform-origin: 0;
}

.hero--contact-stripes-inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1440px;
  height: 100%;
}

.hero--contact-stripe {
  position: absolute;
}

.hero--contact-stripe-1 {
  position: absolute;
  z-index: 1;
  bottom: 588px;
  left: -400px;
  width: 524px;
  height: 198px;
}

.hero--contact-stripe-1:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(217deg,  #ffffff 0%,  rgba(255, 255, 255, 0) 100%);
  opacity: .2;
}

.hero--contact-stripe-1:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 0;
  width: calc(100% + 50px);
  height: 1px;
  background-color: #ffffff;
}

.hero--contact-stripe-2 {
  position: absolute;
  z-index: 1;
  right: -266px;
  bottom: 280px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(217deg,  rgba(255,110,137,0.4) 0%,  rgba(255,177,107,0.63) 100%);
}

.hero--contact-stripe-2:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -44px;
  left: 17px;
  width: 148px;
  height: 64px;
  background-color: #fafcfd;
  opacity: .1;
}

.hero--contact-stripe-2:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 20px;
  left: -95px;
  width: 260px;
  height: 1px;
  background-color: #ffffff;
}

.hero--contact-stripe-3 {
  position: absolute;
  z-index: 1;
  right: -424px;
  bottom: -80px;
  width: 1004px;
  height: 80px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.27) 100%);
}

.hero--contact-stripe-4 {
  position: absolute;
  z-index: 1;
  left: 152px;
  bottom: 0;
  width: 354px;
  height: 100px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.63) 100%);
}

.hero--contact-stripe-4:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 20px;
  width: 260px;
  height: 1px;
  background-color: #ffffff;
}

.hero--contact-stripe-5 {
  position: absolute;
  z-index: 2;
  left: 180px;
  bottom: -30px;
  width: 148px;
  height: 60px;
  background-color: #fafcfd;
  opacity: .3;
}

.hero--contact-stripe-6 {
  position: absolute;
  z-index: 1;
  left: 102px;
  bottom: -80px;
  width: 354px;
  height: 80px;
  background-image: linear-gradient(228deg,  rgba(255,110,137,0.4) 0%, rgba(255,177,107,0.63) 100%);
}

.hero--contact-form {
  width: 100%;
  max-width: 1164px;
  padding: 0 32px;
}

.contact--input,
.contact--textarea {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  height: 70px;
  border: 0;
  border-radius: 4px;
  padding: 26px 30px 26px 55px;
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, .1);
}

.contact--textarea {
  height: 180px;
  resize: none;
}

.contact--input:focus,
.contact--textarea:focus {
  outline: none;
}

.contact--input:focus + .contact--form-icon img,
.contact--textarea:focus + .contact--form-icon img {
  opacity: 1;
}

.contact--form-icon {
  position: absolute;
  z-index: 1;
  top: 26px;
  left: 24px;
  width: 18px;
  height: 18px;
  text-align: center;
}

.contact--form-icon img {
  height: 18px;
  opacity: .5;
}

.contact--button {
  cursor: pointer;
  width: 100%;
  height: 65px;
  margin-right: 16px;
  color: #fff !important;
  background-color: #aa63ff !important;
  box-shadow: 0 8px 26px 0 rgba(170, 99, 255, 0.30);
}

.contact--form-separator {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.contact--grid {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: auto;
}

.contact--form-title {
  grid-column-start: 1;
  grid-row-start: 1;
  padding: 0;
}

.contact--form-alternative {
  grid-column-start: 1;
  grid-row-start: 3;
  margin-top: 60px;
  padding: 0 0 80px;
}

.contact--form-wrapper {
  grid-column-start: 1;
  grid-row-start: 2;
  margin-top: 60px;
  padding: 0;
}

.contact--social-list {
  margin: 8px 0 0;
  padding: 0;
  list-style: none;
}

.contact--social-list a + a {
  margin-left: 24px;
}

.contact--social-list a img {
  height: 28px;
}

/* Responsive */

@media (min-width: 768px) {
  .hero--contact {
    padding-top: 240px;
    padding-bottom: 240px;
  }
  
  .hero--contact-stripe-1 {
    left: -200px;
  }

  .hero--contact-stripe-2 {
    right: -66px;
  }
  
  .contact--grid {
    display: grid;
    grid-gap: 0;
    grid-template-columns: calc(100% - 360px) 360px;
    grid-template-rows: auto 1fr;
  }
  
  .contact--form-title {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  
  .contact--form-alternative {
    grid-column-start: 1;
    grid-row-start: 2;
    margin-top: 120px;
    padding: 0 60px 0 0;
  }
  
  .contact--form-wrapper {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .contact--grid {
    grid-template-columns: calc(100% - 466px) 466px;
  }

  .contact--form-alternative {
    margin-top: 150px;
    padding: 0 112px 0 0;
  }
}

@media (min-width: 1920px) {
  .hero--contact-stripes {
    transform: skewY(-20deg);
  }
}