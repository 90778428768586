
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222A41;
  background: #FAFAFA;
  line-height: 1.65;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  align-items: flex-end;
  width: 100vw;
  /* border-bottom: 1px solid #ccc; */
  /* padding: 32px; */
  /* height: 50px; */
  padding: 40px 32px !important;
}

.main--navbar {
  position: relative;
  z-index: 999;
  width: 100%;
  background-color: transparent;
  transition: all ease .5s;
}

.main--navbar.scrolled {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 12px 30px 0 rgba(55, 74, 105, .05);
  transition: all ease .5s;
}

.main--navbar .navbar {
  transition: all ease .5s;
}

.main--navbar.scrolled .navbar {
  padding: 25px 32px !important;
  transition: all ease .5s;
}

.navbar__wrapper {
  margin-left: 56px;
}

.navbar__items {
  display: flex;
  line-height: 1;
  flex: 1;
  align-items: flex-end;
  /* justify-content: space-between; */
}

.navbar__items > a {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}

.navbar__items > a:not(.active) {
  opacity: 0.6;
}

.navbar-outer--active .navbar__items > a:not(.active) {
  color: rgb(130, 129, 138);
  opacity: 1;
}

.navbar-outer--active .navbar__items > a.active {
  color: #5DAAF2;
}

.navbar-outer--active .navbar__user > a {
  color: rgb(130, 129, 138);
}

.navbar-outer--active .navbar__user > a.btn__user--join {
  color: #5DAAF2;
  border: 1px solid #5DAAF2;
}

.navbar__items > a + a {
  margin-left: 30px;
}

.navbar__items > li:last-of-type {
  margin-right: 0;
}

.navbar__user > a {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  color: #ffffff;
}

.navbar__user > a.btn__user--join {
  padding: 10px 25px;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 3px;
}

.navbar__user > a + a {
  margin-left: 30px;
}

.hero {
  /* navbar height is 90px */
  padding-top: 120px;
}

.navbar__logo {
  width: 90px;
  /* margin-right: 32px; */
}


/* .cards > div:nth-child(odd) {
  background: #FAFAFA;
} */

ul > li,ol > li {
  margin-bottom: 16px;
}

ul, ol {
  padding-top: 16px;
}

.hamburger-icon {
  display: none !important;
}

.hamburger-icon-wrap {
  /* display: none; */
}

@media (max-width: 1440px) {
  .brand-stripes {
   overflow-x: hidden;
   overflow-y: hidden;
   /* overflow-y: scroll; */
  }
}

@media (max-width: 1000px) {
  .navbar__items,
  .navbar__user {
  display: none;
  flex: unset;
  }

  .hamburger-icon-wrap {
    flex: 1;
    /* display: none; */
  }

  .hamburger-icon {
    display: inline-block !important;
  }
}

.burger-menu-open  {
  box-shadow:   0 0 48px 0 rgba(0,0,0,0.9);
}
.bm-menu {
  background: #373a47;
}

.bm-item-list {
  margin-top: 48px;
}

.bm-item {
  padding: 16px 48px;
  color: white;
  text-decoration: none;
}

.bm-item.active {
  font-weight: bold;
}