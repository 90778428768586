.hero--about-us {
  position: relative;
  z-index: 3 !important;
  min-height: 1024px;
  background: initial !important;
}

.hero--about-us__grid {
  grid-template-columns: 1fr;
  column-gap: 40px;
  row-gap: 32px;
}

.hero--about-us-stripes {
  position: absolute;
  z-index: 0;
  transform: skewY(-20deg) translateY(-1152px);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 1920px;
  background: #f2eeff !important;
}

.hero--about-us-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hero--about-us-stripe-1 {
  position: absolute;
  bottom: 664px;
  left: -112px;
  width: 284px;
  height: 104px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.hero--about-us-stripe-2 {
  position: absolute;
  bottom: 630px;
  left: 98px;
  width: 148px;
  height: 54px;
}

.hero--about-us-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--about-us-stripe-2:after {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--about-us-stripe-3 {
  position: absolute;
  bottom: 536px;
  left: 80px;
  width: 354px;
  height: 130px;
}

.hero--about-us-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.hero--about-us-stripe-3:after {
  content: '';
  position: absolute;
  top: 82px;
  right: -80px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--about-us-stripe-4 {
  position: absolute;
  bottom: 470px;
  left: 146px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--about-us-stripe-5 {
  position: absolute;
  bottom: -30px;
  left: -50px;
  width: 142px;
  height: 52px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.hero--about-us-stripe-6 {
  position: absolute;
  right: -46px;
  bottom: 127px;
  width: 284px;
  height: 104px;
}

.hero--about-us-stripe-6:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.hero--about-us-stripe-6:after {
  content: '';
  position: absolute;
  top: 35px;
  left: -48px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--about-us-stripe-7 {
  position: absolute;
  right: -16px;
  bottom: 0;
  width: 354px;
  height: 128px;
}

.hero--about-us-stripe-7:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .7;
}

.hero--about-us-stripe-7:after {
  content: '';
  position: absolute;
  top: -26px;
  left: 70px;
  width: 148px;
  height: 54px;
  background-color: #FEFEFE;
  opacity: .3;
}

/* Team */
.team--about-us {
  position: relative;
  z-index: 2 !important;
  margin-top: 120px !important;
}

.team--about-us-stripes {
  position: absolute;
  z-index: 0;
  transform: skewY(-20deg) translateY(-1152px);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 1920px;
}

.team--about-us-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.team--about-us-stripe-1 {
  position: absolute;
  bottom: 444px;
  right: -15px;
  width: 354px;
  height: 130px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .3;
}

.team--about-us-stripe-2 {
  position: absolute;
  bottom: -244px;
  right: -15px;
  width: 1004px;
  height: 366px;
  background-image: linear-gradient(60deg, #9BE5FF, #CE90FF);
  opacity: .2;
}

.team--about-us-stripe-3 {
  position: absolute;
  bottom: 400px;
  left: -98px;
  transform: skewY(40deg);
  width: 198px;
  height: 72px;
}

.team--about-us-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.team--about-us-stripe-3:after {
  content: '';
  position: absolute;
  top: 40px;
  right: -76px;
  width: 278px;
  height: 1px;
  background-color: #D1C5FF;
}

.team--about-us-stripe-4 {
  position: absolute;
  bottom: 400px;
  left: -188px;
  transform: skewY(40deg);
  width: 248px;
  height: 92px;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.team--about-us__content {
  position: relative;
  z-index: 1;
}

.team--about-us__grid {
  grid-template-columns: 1fr;
}

/* Building */
.building--about {
  position: relative;
  padding: 92px 0 128px;
}

.building--about-grid {
  grid-template-columns: 1fr;
}

.building--about-stripes {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: skewY(20deg) translate(-50%, -800px);
  transform-origin: 0% 50%;
  flex: 1;
  width: 100vw;
  height: 1920px;
  background: linear-gradient(120deg, #5E7EF9, #FFA2BA) !important;
}

.building--about-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.building--about-stripe-1 {
  position: absolute;
  top: 242px;
  left: 262px;
  width: 198px;
  height: 72px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.building--about-stripe-2 {
  position: absolute;
  top: 284px;
  left: 172px;
  width: 248px;
  height: 92px;
}

.building--about-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.building--about-stripe-2:after {
  content: '';
  position: absolute;
  top: 0;
  left: -82px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.building--about-stripe-3 {
  position: absolute;
  top: 100px;
  right: -172px;
  width: 520px;
  height: 126px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.building--about-stripe-4 {
  position: absolute;
  top: 65px;
  right: 72px;
  width: 218px;
  height: 80px;
}

.building--about-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9630F0;
  opacity: .1;
}

.building--about-stripe-4:after {
  content: '';
  position: absolute;
  top: 0;
  left: -92px;
  width: 408px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.building--about-stripe-5 {
  position: absolute;
  top: 528px;
  right: -80px;
  width: 284px;
  height: 104px;
}

.building--about-stripe-5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.building--about-stripe-5:after {
  content: '';
  position: absolute;
  top: 50px;
  left: -129px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
  opacity: .7;
}

.building--about-content {
  position: relative;
  z-index: 1;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  background: grey;
  margin-bottom: 30px;
}

/* Investor */
.investor--about__grid {
  grid-template-columns: 1fr 1fr;
}

/* Join team */
.join-team--about {
  position: relative;
  padding: 100px 0 100px;
}

.join-team--about-stripes {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  transform: skewY(20deg) translate(-50%, 680px);
  transform-origin: 0% 50%;
  overflow: initial !important;
  flex: 1;
  width: 100vw;
  height: 1920px;
  background: linear-gradient(120deg, #E7F7FF, #DDE9F7) !important;
}

.join-team--about-stripes-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.join-team--about-stripe-1 {
  position: absolute;
  top: 448px;
  left: -745px;
  width: 1004px;
  height: 366px;
}

.join-team--about-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.join-team--about-stripe-1:after {
  content: '';
  position: absolute;
  top: -86px;
  right: -165px;
  width: 418px;
  height: 154px;
  background-color: #FAFCFD;
  opacity: .7;
}

.join-team--about-stripe-2 {
  position: absolute;
  top: 58px;
  right: 170px;
  width: 354px;
  height: 130px;
}

.join-team--about-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #CE90FF, #9BE5FF);
  opacity: .5;
}

.join-team--about-stripe-2:after {
  content: '';
  position: absolute;
  top: 96px;
  left: -82px;
  width: 276px;
  height: 1px;
  background-color: #FAFCFD;
  opacity: .7;
}

.join-team--about-stripe-3 {
  position: absolute;
  top: 950px;
  left: 526px;
  width: 524px;
  height: 192px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .2;
}

.join-team--about-stripe-4 {
  position: absolute;
  top: 540px;
  right: 105px;
  width: 283px;
  height: 104px;
}

.join-team--about-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9DBFF5, #9BE5FF);
  opacity: .7;
}

.join-team--about-stripe-4:after {
  content: '';
  position: absolute;
  top: 70px;
  right: -26px;
  width: 148px;
  height: 54px;
  background-color: #FAFCFD;
  opacity: .3;
}

.join-team--about-stripe-5 {
  position: absolute;
  top: -55px;
  right: 0;
  width: 898px;
  height: 172px;
  background-image: linear-gradient(120deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.join-team--about-stripe-6 {
  position: absolute;
  top: -126px;
  right: -230px;
  width: 1272px;
  height: 126px;
  background-image: linear-gradient(120deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.join-team--about-stripe-7 {
  position: absolute;
  top: -94px;
  left: -188px;
  width: 354px;
  height: 129px;
  background-image: linear-gradient(120deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.join-team--about-stripe-8 {
  position: absolute;
  top: -54px;
  left: 138px;
  width: 148px;
  height: 54px;
}

.join-team--about-stripe-8:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.join-team--about-stripe-8:after {
  content: '';
  position: absolute;
  top: 0;
  left: -62px;
  width: 276px;
  height: 1px;
  background-color: #ffffff;
}

.join-team--about__content {
  position: relative;
  z-index: 1;
}

.join-team--about__grid {
  grid-template-columns: 1fr;
}

.join-team--about__list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
}

.join-team--about__list h2 {
  margin-bottom: 20px !important;
}

/* Responsive */

@media (min-width: 576px) {
  .investor--about__grid {
    max-width: 360px;
  }

  .join-team--about__grid {
    grid-template-columns: 1fr 1fr;
  }

  .join-team--about-stripes {
    transform: skewY(20deg) translate(-50%, 1100px);
  }
}

@media (min-width: 768px) {
  .hero--about-us__grid {
    grid-template-columns: 1fr 1fr;
  }

  .building--about-stripes {
    transform: skewY(20deg) translate(-50%, -500px);
  }

  .building--about-grid {
    grid-template-columns: 1fr 1fr;
  }

  .investor--about__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 720px;
  }

  .join-team--about-stripes {
    transform: skewY(20deg) translate(-50%, 1000px);
  }

  .join-team--about {
    padding: 200px 0 200px;
  }
}

@media (min-width: 992px) {
  .building--about-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .join-team--about {
    padding: 388px 0 200px;
  }

  .join-team--about-stripes {
    transform: skewY(20deg) translate(-50%, 880px);
  }

  .join-team--about__list {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 48px !important;
  }

  .join-team--about__list h2 {
    margin-bottom: 0 !important;
  }
}