.hero--research {
  min-height: 860px;
  background: initial !important;
  position: relative;
}

.hero--research-stripes {
  position: absolute;
  z-index: 1;
  transform: skewY(-20deg) translateY(-1240px);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 1920px;
  background-image: linear-gradient(60deg, #5EE3AE 10%, #46A8F3 70%) !important;
}

.hero--research-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hero--research-stripe-1 {
  position: absolute;
  bottom: 700px;
  left: -22px;
  width: 330px;
  height: 105px;
  opacity: .5;
}

.hero--research-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.hero--research-stripe-1:after {
  content: '';
  position: absolute;
  top: 30px;
  left: -46px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--research-stripe-2 {
  position: absolute;
  bottom: 618px;
  left: -121px;
  width: 354px;
  height: 82px;
  opacity: .5;
}

.hero--research-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .5;
}

.hero--research-stripe-2:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 69px;
  width: 148px;
  height: 55px;
  background-color: #fafcfd;
  opacity: .3;
}

.hero--research-stripe-3 {
  position: absolute;
  bottom: 134px;
  left: -143px;
  width: 248px;
  height: 92px;
  opacity: .7;
}

.hero--research-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .5;
}

.hero--research-stripe-3:after {
  content: '';
  position: absolute;
  bottom: -74px;
  left: 106px;
  width: 198px;
  height: 74px;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .5;
}

.hero--research-stripe-4 {
  position: absolute;
  bottom: 386px;
  left: 50%;
  transform: translateX(calc(-50% - 80px));
  width: 354px;
  height: 130px;
  opacity: .5;
  background-color: #5ADABA;
  opacity: .5;
}

.hero--research-stripe-5 {
  position: absolute;
  z-index: 1;
  right: 454px;
  bottom: -25px;
  width: 255px;
  height: 94px;
  transform-origin: 0 100%;
}

.hero--research-stripe-5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--research-stripe-5:after {
  content: '';
  position: absolute;
  top: 0;
  left: -86px;
  width: 478px;
  height: 1px;
  background-color: #FEFEFE;
}

.hero--research-stripe-6 {
  position: absolute;
  z-index: 0;
  right: -113px;
  bottom: -80px;
  width: 613px;
  height: 225px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .7;
}

.hero--research-stripe-7 {
  position: absolute;
  z-index: 0;
  right: -170px;
  bottom: 242px;
  width: 354px;
  height: 90px;
  background-image: linear-gradient(60deg,  #9BE5FF 30%,  #CE90FF);
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--research-stripe-8 {
  position: absolute;
  bottom: 212px;
  right: 86px;
  width: 148px;
  height: 54px;
  opacity: .5;
}

.hero--research-stripe-8:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fafcfd;
  opacity: .2;
}

.hero--research-stripe-8:after {
  content: '';
  position: absolute;
  top: 0;
  right: -22px;
  width: 276px;
  height: 1px;
  background-color: #fafcfd;
}

.hero--research-stripe-9 {
  position: absolute;
  bottom: -452px;
  right: -58px;
  width: 354px;
  height: 130px;
}

.hero--research-stripe-9:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #46A8F3, #82F9C9);
  opacity: .3;
}

.hero--research-stripe-9:after {
  content: '';
  position: absolute;
  top: -40px;
  right: -54px;
  width: 283px;
  height: 105px;
  background-image: linear-gradient(45deg, #88AFFF, #CE90FF);
  opacity: .7;
}

.hero--technology {
  margin-top: 320px !important;
}

.hero--technology-stripes {
  position: absolute;
  z-index: 1;
  transform: skewY(20deg) translateY(0);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 800px;
  background-image: linear-gradient(200deg, rgb(216, 89, 113) 0%, #FFB16B 100%) !important;
}

.hero--technology-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hero--technology-stripe-1 {
  position: absolute;
  z-index: 1;
  top: -650px;
  left: -170px;
  width: 248px;
  height: 92px;
}

.hero--technology-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--technology-stripe-1:after {
  content: '';
  position: absolute;
  top: -48px;
  right: -85px;
  width: 198px;
  height: 72px;
  background-image: linear-gradient(45deg, #CE90FF, #9BE5FF);
  opacity: .7;
}

.hero--technology-stripe-2 {
  position: absolute;
  z-index: 0;
  top: -660px;
  left: -869px;
  width: 1080px;
  height: 140px;
  background-image: linear-gradient(45deg, #FFB3B7, #FE7C81);
  opacity: .4;
}

.hero--technology-stripe-3 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -240px;
  width: 614px;
  height: 226px;
}

.hero--technology-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #CE90FF, #E2F8FF);
  opacity: .2;
}

.hero--technology-stripe-3:after {
  content: '';
  position: absolute;
  top: 226px;
  right: 209px;
  width: 256px;
  height: 94px;
  background-color: #fafcfd;
  opacity: .2;
}

.hero--technology-stripe-4 {
  position: absolute;
  z-index: 1;
  bottom: -80px;
  left: -1230px;
  width: 1512px;
  height: 300px;
}

.hero--technology-stripe-4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.hero--technology-stripe-4:after {
  content: '';
  position: absolute;
  bottom: -62px;
  right: -230px;
  width: 1080px;
  height: 144px;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  opacity: .2;
}

.hero--technology-stripe-5 {
  position: absolute;
  z-index: 0;
  right: -16px;
  bottom: 0;
  width: 255px;
  height: 94px;
  background-color: #FAFCFD;
  opacity: .3;
}

.hero--technology-stripe-6 {
  position: absolute;
  z-index: 0;
  right: -192px;
  top: -255px;
  width: 255px;
  height: 255px;
  background-image: linear-gradient(90deg, #FFB3B7, #FE7C81);
  transform: skewx(-56deg);
  opacity: .3;
}

/* Hero content */
.hero__content--research {
  width: 100%;
  max-width: 1164px;
  margin: 162px auto 0 !important;
}

.hero__text--research {
  width: 100%;
  padding: 60px 0 0;
}

.hero__button--research {
  margin: 48px 0 0 !important;
}

.hero__image--research {
  position: relative;
  width: 544px;
}

.hero__image--research img {
  position: relative;
  top: -18px;
  left: -80px;
  display: block;
  width: 691px;
  max-width: 691px;
}

/* World class team */
.team--research {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 120px !important;
  padding-bottom: 116px;
}

.team--research:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 64px);
  height: 1px;
  background-color: #d8d8d8;
}

.team--research-stripes {
  position: absolute;
  z-index: 1;
  transform: skewY(-20deg);
  overflow: initial !important;
  flex: 1;
  width: 100%;
  height: 100%;
}

.team--research-stripes-inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.team--research-stripe-1 {
  position: absolute;
  right: -175px;
  bottom: -160px;
  width: 330px;
  height: 105px;
}

.team--research-stripe-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .75;
}

.team--research-stripe-1:after {
  content: '';
  position: absolute;
  top: 15px;
  left: -94px;
  width: 276px;
  height: 1px;
  background-color: #FEFEFE;
}

.team--research-stripe-2 {
  position: absolute;
  right: -138px;
  bottom: -240px;
  width: 354px;
  height: 82px;
}

.team--research-stripe-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #82F9C9, #46A8F3);
  opacity: .2;
}

.team--research-stripe-2:after {
  content: '';
  position: absolute;
  top: -27px;
  left: 29px;
  width: 148px;
  height: 55px;
  background-color: #fafcfd;
  opacity: .3;
}

.team__wrapper--research {
  grid-template-columns: 100%;
}

.team__image--research {
  position: relative;
  left: -87px;
  width: 474px;
  height: 321px;
  padding: 65px 0 0;
}

.team__image--research img {
  display: block;
  position: relative;
  top: -19px;
  left: -31px;
  width: 622px;
  max-width: 622px;
}

.team__text--research {
  padding: 0;
}

.team__text--research h2 {
  font-size: 36px;
  line-height: 50px;
  color: #131415;
  max-width: 353px;
}

.team__desc--research {
  margin: 24px 0 0 !important;
  font-size: 17px;
  line-height: 32px;
  color: #7b7b7b;
}

.team__button--research {
  margin: 40px 0 0 !important;
}

/* Research lead */
.lead--research {
  margin-bottom: 102px !important;
}

.lead__title--research {
  max-width: 460px;
  margin: 0 auto 66px !important;
  font-size: 36px !important;
  line-height: 49px;
  text-align: center;
}

.lead__text--research img {
  display: block;
  max-width: 114px;
  border-radius: 50%;
  margin: 0 auto 40px;
}

.lead__text--research h2 {
  margin: 0 0 24px;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}

.lead__desc--research {
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  color: #737b8b;
}

.count--technology {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

/* Data science */
.data__wrapper--research {
  grid-template-columns: 100%;
}

.data__image--research {
  position: relative;
  left: -20px;
  width: 510px;
  height: 363px;
}

.data__image--research img {
  display: block;
  position: relative;
  top: -18px;
  left: -80px;
  bottom: -142px;
  width: 622px;
  max-width: 622px;
}

.data__text--research {
  padding: 0;
}

.data__text--research h2 {
  max-width: 340px;
  font-size: 36px;
  line-height: 50px;
}

.data__desc--research {
  margin: 24px 0 0;
  font-size: 17px;
  font-weight: normal;
  line-height: 32px;
}

.data__button--research {
  margin: 40px 0 0 !important;
}

/* Ecological research */
.ecological--research {
  margin-top: 200px !important;
}

.ecological--research__grid {
  padding: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 32px;
}

.ecological--research__grid-desktop {
  display: none;
}

.ecological--research__grid-mobile {
  display: block;
  margin: 40px 0 0;
}

.tab--research {
  margin-top: 46px;
}

/* Research Evolve */
.evolve--research {
  margin-top: 152px !important;
  margin-bottom: 152px !important;
}

.evolve__image--research {
  display: block;
  max-width: 292px !important;
  margin: 75px auto 0 !important;
}

/* Responsive */

@media (min-width: 768px) {
  .hero--research {
    min-height: 939px;
  }

  .hero__content--research {
    margin: 204px auto 0 !important;
  }

  .hero--technology-stripes {
    transform: skewY(20deg) translateY(-50px);
  }

  .ecological--research {
    margin-top: 400px !important;
  }

  .ecological--research__grid {
    padding: 40px;
  }
  .count--technology {
    flex-direction: row;
  }
  .ecological--research__grid {
    grid-template-columns: 360px auto;
  }
  .evolve--research {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .hero__text--research {
    width: 333px;
    padding: 60px 0 0;
  }

  .team__text--research {
    padding: 0 0 0 56px;
  }

  .team__wrapper--research {
    grid-template-columns: 474px calc(100% - 474px);
  }

  .data__wrapper--research {
    grid-template-columns: 490px calc(100% - 490px);
  }

  .data__text--research {
    padding: 0 0 0 78px;
  }

  .ecological--research__grid {
    padding: 80px;
  }
}

@media (min-width: 1200px) {
  .ecological--research__grid-desktop {
    display: block;
  }
  
  .ecological--research__grid-mobile {
    display: none;
  }
}