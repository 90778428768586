.hero__title {
  margin: 0 !important;
  font-size: 42px !important;
  font-weight: 700 !important;
  line-height: 57px;
}

.hero__subtitle {
  margin: 16px 0 0 !important;
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 30px;
}

/* Custom tabs */
.custom--tabs {
  background-color: #ffffff;
  box-shadow: 0 22px 60px 0 rgba(24, 50, 92, .1);
}

.tab__header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #d8d8d8;
}

.tab__nav--list {
  cursor: pointer;
  margin-bottom: -1px;
  padding: 20px 0;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #7b7b7b;
  border-bottom: 1px solid #d8d8d8;
}

.tab__nav--list:focus {
  box-shadow: none;
  outline: none;
}

.tab--active {
  font-weight: 700;
  color: #aa63ff;
  border-bottom: 1px solid #aa63ff;
}

/* Button */
.btn {
  position: relative;
  top: 0;
  transition: all ease .3s;
}

.btn:hover {
  top: -3px;
  transition: all ease .3s;
}

/* Footer */
.hero--footer {
  position: relative;
  z-index: 1;
  padding: 550px 0 80px;
  background: initial !important;
}

.hero--footer-stripes {
  flex: 1;
  position: absolute;
  top: 305px;
  z-index: 1;
  transform: skewY(-20deg) translateY(0);
  transform-origin: 50% 0;
  width: 100%;
  height: 1584px;
  background: linear-gradient(160deg, #5E7EF9 20%, #FFA2BA 70%) !important;
}

.hero--footer-stripes-inner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
}

.hero--footer-stripe-1 {
  position: absolute;
  z-index: 0;
  top: -47px;
  left: 267px;
  width: 255px;
  height: 94px;
  background-color: #fff;
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--footer-stripe-2 {
  position: absolute;
  z-index: 0;
  left: 931px;
  top: 610px;
  width: 675px;
  height: 250px;
  background-color: #fff;
  transform-origin: 0 100%;
  opacity: .2;
}

.hero--footer-stripe-3 {
  position: absolute;
  top: 130px;
  left: 1133px;
  width: 614px;
  height: 227px;
  transform-origin: 0 100%;
}

.hero--footer-stripe-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #9be5ff, #cd91ff);
  opacity: .7;
}

.hero--footer-stripe-3:after {
  content: '';
  position: absolute;
  top: 227px;
  left: 209px;
  width: 255px;
  height: 94px;
  background-color: #FEFEFE;
  opacity: .3;
}

.hero--footer-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.hero--footer-left {
  margin: 100px 0 0 !important;
  text-align: center;
}

.hero--footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero--footer-menu {
  text-align: center;
}

.hero--footer-menu + .hero--footer-menu {
  margin: 50px 0 0 !important;
}

/* Responsive */

@media (min-width: 768px) {
  .hero--footer-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .hero--footer-menu {
    text-align: left;
  }

  .hero--footer-menu + .hero--footer-menu {
    margin: 0 0 0 80px !important;
  }
}

@media (min-width: 1200px) {
  .hero--footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .hero--footer-left {
    margin: 0 !important;
    text-align: left;
  }
}